.dumbbell-video-container{
  .dumbbell-trainee-search-sec-v2{
    display: flex;
    justify-content:center;
    margin: 20px 20px 10px 20px ;
    .dumbbell-trainee-search{
      background-color: #F6F6F6;
      border-radius: 10px;
      display: flex;
      align-items: center;
      height: 55px;
      padding: 0 10px;
      width: 100%;
      .dumbbell-trainee-filter{
        background-color: #8D89FD;
        padding: 15px 13px;
        border-radius: 8px 0 0 8px;
        select{
          font-family: YekanBold;
          border: none;
          outline: none;
          background-color: #8D89FD;
          color: #ffffff;
          text-align: center;
        }
      }
      input{
        width: 100%;
        border: none;
        text-align: center;
        font-family: YekanRegular;
        outline: none;
        font-size: 15px;
        padding: 0 10px;
        direction:rtl;
        background: transparent;
      }
      img{
        padding: 15px 13px;
      }
    }

  }

  .dumbbell-video-list{
    padding-bottom: 100px;
    .dumbbell-video-card{
      background-color: #F3F3F3;
      margin: 10px 20px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .dumbbell-video-card-main{
        display: flex;
        padding: 8px;
      }
      .dumbbell-video-card-number{
        width: 50px;
        border-radius: 10px 0 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:white;
        font-family: YekanBold;
        font-size: 18px;
        .delete-video{
          background-color: #bcb9fc;
          width:100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border-radius: 10px 0 0 0;
        }
        .edit-video{
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width:100%;
         background-color: white;
          border-left: 1px solid lightgrey;
          border-bottom: 1px solid lightgrey;
          border-radius: 0 0  0 10px;
        }
      }
      .dumbbell-video-card-info{
        display: flex;
        flex-direction: column;
        align-items:flex-end;
        margin: 0 10px;
        justify-content:center;
        .dumbbell-video-card-info-title{
          direction: rtl;
          font-family: YekanBold;
        }
        .dumbbell-video-card-info-type{
          .dumbbell-video-card-info-type-public{
            font-family: YekanMedium;
            color: #8D89FD;
          }
          .dumbbell-video-card-info-type-me{
            font-family: YekanMedium;
            color: #E7BE3B;
          }
        }
      }

      .dumbbell-video-card-video{
        width: 60px;
        height: 60px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

  }


}

.video-tabs{
  margin: 0 20px;
}


.top-shower{
  padding: 12px;
  img{
    width: 100%;
  }
}

.select-video{
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  background-color: #6277f8;
}