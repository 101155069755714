.printable-plan-container{
  .printable-plan-header{
    border:1px solid lightgray;
    border-radius: 8px;
    margin: 10px 10px;
    .printable-plan-header-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px;
      .printable-plan-header-item{
        display: flex;
        align-items: center;
        direction: rtl;
        h6{
          font-family: YekanRegular;
          font-size: 12px;
          color: lightgray;
        }
        span{
          font-family: YekanBold;
          margin:2px 10px;
          font-size: 12px;
        }
      }

    }

  }
}

.print-day-card{
  direction:rtl;

  .print-day-card-header{
    display: flex;
    justify-content: flex-start;
    font-family: YekanBold;
    margin: 15px 15px;
  }

  .print-day-card-body{
    border-radius: 10px;
    border:1px solid lightgray;
    background-color: white;
    margin: 15px 15px;
  }
  .print-day-card-body-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    margin: 0 10px;
    padding: 5px 0;
  }

  .print-day-card-body-item{
    font-family: YekanRegular;
    font-size: 12px;
  }

  .print-day-card-body-item-header{
    font-family: YekanBold;
    font-size: 12px;
  }

  .print-col-1{
    text-align: center;
    flex:1
  }
  .print-col-2{
    text-align: right;
    margin: 0 5px;
    flex:2;
    span{
      word-break: break-all;
    }
  }

}

.index-item{
  background-color:lightgray;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin:0 2px;
  font-family: YekanBold !important;
}

.alter-sec{
  .alter-title{
    display: flex;
    font-family: YekanBold !important;
    padding: 10px 5px 2px 20px !important;
    font-size: 14px;
    .alter-title-number{
      background-color:lightgray;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items:center;
      margin:0 2px;
      font-family: YekanBold !important;
    }
    .alter-title-text{
      font-family: YekanBold !important;
    }
  }
  .alter-list{
    background-color:#F9F9F9;
    margin: 5px 10px;
    border-radius: 5px;
  }
}

.super-sec{
  .super-title{
    display: flex;
    font-family: YekanBold !important;
    padding: 10px 10px 2px 20px !important;
    font-size: 14px;
    .super-title-number{
      background-color:lightgray;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items:center;
      margin:0 2px;
      font-family: YekanBold !important;
    }
    .super-title-text{
      font-family: YekanBold !important;
    }
  }
  .super-list{
    background-color: #C5C2C2;
    margin: 5px 4px;
    border-radius: 5px;
  }
}

.index-item-diet{
  background-color:lightgray;
  width: 40px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin:0 2px;
  font-family: YekanBold !important;
}
