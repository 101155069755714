.dumbbell-login-container{

  .dumbbell-login-image{
    display: flex;
    justify-content: center;
    margin: 60px 0 0 0;
  }

  .dumbbell-login-logo{
    display: flex;
    justify-content: center;
    margin: 5px 0 0 0;
  }

  .dumbbell-login-input-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    .dumbbell-login-input{
      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 7px 12px;
      border-radius:8px;
      input{
        width: 200px;
        text-align: center;
        font-family: YekanRegular;
        outline:none;
        border: none;
        font-size: 15px;
      }
    }
  }

  .dumbbell-login-btn{
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      background-color:#B667E5;
      color: white;
      border-radius:8px;
      width:235px;
      text-align: center;
      padding:8px 10px;
      font-family: YekanRegular;
      cursor: pointer;
    }
    span:active{
      opacity:0.3
    }
  }

  .dumbbell-login-copyright{
    position:absolute;
    bottom:10px;
    text-align: center;
    width:100%;
    font-size:12px
  }

}

.mode-sec{
  display:flex;
  justify-content:center;
  align-items:center;
  margin: 15px 0;
  span{
    font-family: YekanBold;
    color: white;
    margin: 0 10px;
  }
}
