.dumbbell-menu-container{
  //background-color: #C6C3FF;
  height: 100vh;

  .dumbbell-menu-image:active{
    opacity: 0.3;
  }
  .dumbbell-menu-image{
    cursor: pointer;
    display:flex;
    justify-content: center;
    padding: 30px 0 10px 0;
    .dumbbell-menu-image-sec{
      width: 100px;
      height: 100px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
      }
    }

  }

  .dumbbell-menu-name{
    font-family: YekanBold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .dumbbell-menu-item:active{
    opacity: 0.3;
  }
  .dumbbell-menu-item{
    cursor: pointer;
    display: flex;
    background-color:white;
    margin: 10px 10px;
    justify-content:flex-end;
    align-items: center;
    padding:10px 20px;
    height:60px;
    border-bottom: 1px solid #f5f5f5;
    span{
      font-family: YekanMedium;
      margin: 0 15px 0 0;
    }
  }

}

.dumbbell-menu-image-upload-btn{
  display: flex;
  justify-content: center;
  span:active{
    opacity:0.3
  }
  span{
    font-size:13px;
    cursor: pointer;
    background-color: #2f54eb;
    color: white;
    font-family: YekanMedium;
    padding: 4px 20px;
    border-radius: 4px;
  }
}
.step-menu-btn{
  display: flex;
  justify-content: center;
  margin: 20px 0 50px 0;
  span{
    background-color: #8D89FD;
    color: white;
    font-family: YekanBold;
    width: 250px;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
  }
}

.img-sec{
  width: 32px;
  text-align: right;
}

.notifications-sec{
  display: flex;
  justify-content: center;
  .notification-btn{
    background-color: royalblue;
    color:white;
    font-family: YekanRegular;
    width: 80%;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .notification-label{
    color:#b5b5b5;
    font-family: YekanRegular;
    width: 80%;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
}