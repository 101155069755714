.dumbbell-trainee-container{

  .dumbbell-trainee-search-sec{
    display: flex;
    justify-content:center;
    margin: 0 20px;
    .dumbbell-trainee-search{
      display: flex;
      background-color:white;
      border-radius:8px;
      width: 100%;
      .dumbbell-trainee-filter{
        background-color: #EFEFEF;
        padding: 15px 13px;
        border-radius: 8px 0 0 8px;
        select{
          font-family: YekanBold;
          border: none;
          outline: none;
          background-color: #EFEFEF;
          color: #7D7777;
        }
      }
      input{
        width: 100%;
        border: none;
        text-align: right;
        font-family: YekanRegular;
        outline: none;
        font-size: 15px;
        padding: 0 10px;
        direction:rtl;
      }
      img{
        padding: 15px 13px;
      }
    }

  }

  .dumbbell-trainee-total{
    display: flex;
    justify-content: space-between;
    margin: 4px 25px;
    .dumbbell-trainee-total-sec{
      font-family: YekanBold;
      color: white;
      direction: rtl;
    }

    .dumbbell-trainee-unread-message:active{
      opacity:0.3
    }
    .dumbbell-trainee-unread-message{
      cursor: pointer;
      //background-color: white;
      border-radius: 8px;
      font-family: YekanRegular;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .message-label{
        color: white;
        text-align: center;
        font-family: YekanBold;
        margin: 0 5px;
      }
      .message-badge{
        background-color:#f36e6e;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content:center;
        align-items: center;
        border-radius: 10px;
      }
    }
  }
  .dumbbell-trainee-list-trainee{
    margin: 172px 0 0 0;
    padding-bottom: 80px;
  }

  .dumbbell-trainee-card{
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #B6B6B6;
    margin: 10px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    .dumbbell-trainee-card-trainee{
      display: flex;
      align-items: center;
      .dumbbell-trainee-card-trainee-info:active{
        opacity:0.3
      }
      .dumbbell-trainee-card-trainee-info{
        cursor: pointer;
        display: flex;
        flex-direction:column;
        align-items:flex-end;
        margin: 0 10px;
        .dumbbell-trainee-card-trainee-info-name-v2{
          font-family: YekanHeavy;
          direction:rtl;
          font-size: 14px;
        }
        .alert-message{
          background-color: #FF8989;
          color: white !important;
          border-radius:4px;
          padding: 2px 10px;
        }
        .dumbbell-trainee-card-trainee-info-mobile-v2{
          font-family: YekanMedium;
          font-size: 13px;
          color: black;
        }
      }
    }



  }

  .fix-top-sec{
    position: fixed;
    z-index: 2;
    background-color: #8a86fe;
    width: 96%;
    top: 0;
    .fix-content{
      padding: 0;
    }
  }

}

@media screen and (min-width: 800px) {
  .fix-top-sec{
    width: 41vw !important;
  }
}

.register-first-trainee{
  display: flex;
  flex-direction: column;
  align-items:center;
  .register-first-trainee-text{
    font-family: YekanRegular;
    color: white;
    font-size:16px
  }
  .register-first-trainee-btn{
    position: relative;
    background-color: #B667E5;
    color: white;
    border-radius: 8px;
    width: 255px !important;
    text-align: center;
    padding: 8px 10px;
    font-family: YekanRegular;
    cursor: pointer;
  }
}

.dumbbell-trainee-card-actions{
  display: flex;
  align-items: center;
  div{
    margin: 0 10px;
  }
  .dumbbell-trainee-card-box-v2:active{
    opacity: 0.3;
  }
  .dumbbell-trainee-card-box-v2{
    position: relative;
    cursor: pointer;
    img{
      width: 22px;
    }
  }
}

.dumbbell-trainee-card-message:active{
  opacity: 0.3;
}
.dumbbell-trainee-card-message{
  margin: 0 0 0 15px;
  position: relative;
  cursor: pointer;

}

.dumbbell-trainee-card-trainee-delivery{
  position: absolute;
  direction: rtl;
  left: 0;
  top: 0;
  border-radius: 5px 0 5px 0;
  padding:0px 10px;
  span{
    font-family: YekanRegular;
    color: white;
    font-size:12px
  }
}

.dumbbell-trainee-card-trainee-thumb:active{
  opacity:0.3
}
.dumbbell-trainee-card-trainee-thumb{
  cursor: pointer;
  width: 80px;
  height: 80px;
  .trainee-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 3px solid white;
  }
}

.plan-card-delivery_date{
  color: #b4b4b4;
}


.deletable-comp{
  position: relative;
  .deletable-comp-body{
    z-index: 10;
  }
  .delete-mode{
    z-index: 10;
  }
}

.delete-mode{
  position: absolute;
  background-color: #f5f5f5eb;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  margin: 0 20px;
  border-radius: 4px;
  .delete-mode-title{
    font-family: YekanMedium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 25px;
      margin: 15px 0;
    }
    span{
      font-size: 13px;
    }
  }
  .delete-mode-btn{
    font-family: YekanRegular;
    display: flex;
    margin: 0 0;
    span{
      margin: 10px 20px;
      border-radius: 5px;
      padding: 3px 30px;
      cursor: pointer;
    }
    span:active{
      opacity: 0.3;
    }
    .delete-mode-btn-yes{
      background-color: #fd4c4c;
      color: white;
      display: flex;
    }
    .delete-mode-btn-no{
      background-color: lightgray;
      color: black;
      display: flex;
    }

  }
}

.delete-mode-spin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.83);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  img{
    width: 80px;
  }
  span{
    color:#4ea9ff
  }
}

.deletable-comp{
  position: relative;
  .deletable-comp-body{
    z-index: 10;
  }
  .delete-mode{
    z-index: 10;
  }
}

.plan-duration{
  display: flex;
  align-items: center;
  font-family: YekanBold;
  direction: rtl;
}

.fix-top-sec-v2{
  .fix-content{
    padding: 0;
  }
}

.my-trainee-page{
  padding: 0px 20px 10px 20px;
}
.dumbbell-trainee-list-trainee-v2{
  padding-bottom: 80px;
}

.trainee-card-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.float-add-btn-v2{
  position: fixed;
  bottom: 50px;
  right:20px;
  background-color: rgba(141, 137, 253, 0.86);
  border-radius: 5px;
  padding: 17px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size:22px;
  z-index:10
}

.trainee-card-last-visit{
  margin: 0 24px 0 0;
  span{
    font-family: YekanRegular;
    font-size: 12px;
    color: #b1b1b1;
  }
}


.trainee-filters{
  margin: 0 25px;
}
.dumbbell-trainee-card-message-badge{
  position: absolute;
  background-color: #FF8989;
  color: white;
  padding: 3px 8px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  left: 14px;
  font-size: 12px;
}