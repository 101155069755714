.dumbbell-chat-container{
  .dumbbell-chat-header-container{
    display: flex;
    justify-content: flex-end;
    background-color: #a4a2ff;
    position: relative;
    padding: 10px 5px;
    .dumbbell-chat-header-container-info{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0  10px;
      .name{
        font-family: YekanBold;
        color: white;
      }
      .mobile{
        font-family: YekanThin;
        color: white;
        font-size: 13px;
      }
    }

    .dumbbell-chat-header-container-img{
      position: absolute;
      left: 20px;
      top: 15px;
      img{
        width: 80px;
      }
    }

  }
}

.dumbbell-chat-body-another-wr{
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px;
  .dumbbell-chat-body-another-card{
    border-radius: 30px 7px  7px 7px;
    background-color: #fff6e3;
    width: 100%;
    padding: 10px;
    .dumbbell-chat-body-text{
      direction: rtl;
      font-family: YekanRegular;
    }
    .dumbbell-chat-body-date{
      text-align: right;
      font-family: YekanRegular;
      font-size: 11px;
      color: #B8AFAF;
    }
  }

}

.dumbbell-chat-body{
  margin-top: 50px;
}


.dumbbell-chat-body-me-wr{
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px;
  .dumbbell-chat-body-me-card{
    border-radius: 7px 30px  7px 7px;
    background-color: #F6F6F6;
    width: 100%;
    padding: 10px;
    .dumbbell-chat-body-text{
      direction: rtl;
      font-family: YekanRegular;
    }
    .dumbbell-chat-body-date{
      text-align: right;
      font-family: YekanRegular;
      font-size: 11px;
      color: #B8AFAF;
    }
  }

}

.dumbbell-chat-message-box-wr{
  position: fixed;
  bottom: 0;
  background-color: red;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .dumbbell-chat-message-box{
    padding: 10px;
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #F6F6F6;
    input{
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      direction: rtl;
      font-family: YekanMedium;
      padding: 0 10px;
    }
    span{
      border-left: 2px solid gray;
      padding: 0 10px;
      font-family: YekanBold;
      cursor: pointer;
    }
    span:active{
      opacity: 0.3;
    }

  }

}


