.dumbbell-chat-container{
  .dumbbell-chat-header-container{
    display: flex;
    justify-content: flex-end;
    background-color: #a4a2ff;
    position: relative;
    padding: 10px 5px;
    .dumbbell-chat-header-container-info{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0  10px;
      .name{
        font-family: YekanBold;
        color: white;
      }
      .mobile{
        font-family: YekanThin;
        color: white;
        font-size: 13px;
      }
    }

    .dumbbell-chat-header-container-img{
      position: absolute;
      left: 20px;
      top: 15px;
      img{
        width: 80px;
      }
    }

  }
}

.dumbbell-chat-body-another-wr{
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px;
  .dumbbell-chat-body-another-card{
    border-radius: 30px 7px  7px 7px;
    background-color: #fff6e3;
    width: 100%;
    padding: 10px;
    .dumbbell-chat-body-text{
      direction: rtl;
      font-family: YekanRegular;
    }
    .dumbbell-chat-body-date{
      text-align: right;
      font-family: YekanRegular;
      font-size: 11px;
      color: #B8AFAF;
    }
  }

}

.dumbbell-chat-body{
  margin-top: 50px;
  padding-bottom: 100px;
}


.dumbbell-chat-body-me-wr{
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px;
  .dumbbell-chat-body-me-card{
    border-radius: 7px 30px  7px 7px;
    background-color: #F6F6F6;
    width: 100%;
    padding: 10px;
    .dumbbell-chat-body-text{
      direction: rtl;
      font-family: YekanRegular;
    }
    .dumbbell-chat-body-date{
      text-align: right;
      font-family: YekanRegular;
      font-size: 11px;
      color: #B8AFAF;
    }
  }

}

.dumbbell-chat-message-box-wr{
  position: fixed;
  bottom: 0;
  background-color: red;
  width: 100%;
  display: flex;
  justify-content: flex-start;
.dumbbell-chat-message-box{
  padding: 10px;
  display: flex;
  align-items: center;
  width: 300px;
  background-color: #F6F6F6;
    input{
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      direction: rtl;
      padding: 0 10px;
      font-family: YekanMedium;
    }
  span{
    border-left: 2px solid gray;
    padding: 0 10px;
    font-family: YekanBold;
    cursor: pointer;
  }
  span:active{
    opacity: 0.3;
  }

  }

}

.dumbbell-chat-body-more:active{
  opacity: 0.3;
}
.dumbbell-chat-body-more{
  cursor: pointer;
  display: flex;
  margin: 15px 10px;
  background-color: #1890ff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  span{
    color: white;
    font-family: 'YekanBold';
  }
}

.dumbbell-box-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  .clickable:active{
    opacity: 0.3;
  }
  .dumbbell-box-item{
    cursor: pointer;
    width: 130px;
    height: 130px;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    margin: 2px;
    border-radius: 14px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size:cover;
    //filter: saturate(800%);
    .dumbbell-box-item-index{
      direction: rtl;
      font-size: 25px;
      color: #959595;
      z-index: 3;
    }
    input{
      position: absolute;
  }

    position: relative;
  }

}
.mask-box{
  background-color: #000000;
  border-radius: 10px;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.box-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box-upload-btn:active{
  opacity: 0.3;
}
.box-upload-btn{
  z-index: 4;
  color: white;
  cursor: pointer;
  font-family: 'YekanRegular';
  background-color: #1e9bee;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0px;
  margin: 5px 0;
}
.box-cancel-btn:active{
  opacity: 0.3;
}
.box-cancel-btn{
  z-index: 4;
  cursor: pointer;
  color: white;
  font-family: 'YekanRegular';
  background-color: #fa4242;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 0px;
  margin: 5px 0;
}
.ant-spin-text{
  color: #1887ff;
  font-family: 'YekanBold';
  direction: rtl;
  text-shadow: none !important;
}
.delete-sec:active{
  opacity: 0.3;
}
.delete-sec{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff9696;
  padding: 12px 12px ;
  border-radius: 0 8px 0 8px;
  cursor: pointer;
  z-index: 3;
  span{
    color: #ffffff;
  }

}

.wrapper-box{
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.dumbbell-box-container{
  overflow-x:hidden;
}
