.error-draw{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe285;
  padding: 19px;
  .error-text{
    font-family: YekanRegular;
    font-size: 15px;
    direction: rtl;
  }
}

.success-draw{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #adb6fc;
  padding: 19px;
  .error-text{
    font-family: YekanRegular;
    font-size: 15px;
    color: #444444;
    direction: rtl;
  }
}