.track-container{
  margin: 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;




  .track-id{
    display: flex;
    flex-direction: column;
    background-color: #1890ff;
    color: white;
    align-items: center;
    width: 90%;
    margin: 12px 0;
    padding: 12px;
    border-radius: 12px;
    h1{
      font-family: YekanBold;
      color: white;
    }
    span{
      font-family: YekanMedium;
      color: white;
    }
  }
  .track-found-plan{
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: YekanRegular;
  }
  .track-found-booking{

  }
  .track-title{
    h4{
      font-family: YekanBold;
      margin: 0;
      padding: 0;
      direction: rtl;
    }
    span{
      font-family: YekanRegular;
      //background-color: mediumseagreen;
    }
  }


  .plan-is-end{
    background-color: transparent;
    border: 1px solid #b5b5b5;
    color: #b5b5b5;
  }



  .plan-wait-for-pay{
    background-color: transparent;
    border: 1px solid #8D89FD;
    color: #8D89FD;
  }
  .booking-turn-booked-status{
    background-color: #8D89FD;
  }

  .booking-turn-pending-status{
    background-color: #f3b345;
  }

  .plan-wait-for-deliver{
    background-color: #f3b345;
  }

  .plan-delivered{
    background-color: #5dbfc9;
  }
  .plan-wait-for-accept{
    background-color: #f39045;
  }
  .plan-rejected{
    background-color: #ed6e6f;
  }

  .booking-turn-cancel-me-status{
    background-color: #ed6e6f;
  }

  .booking-turn-cancel-client-status{
    background-color: #ed6e6f;
  }

  .booking-turn-accepted-status{
    background-color: #5dbfc9;
  }

  .booking-turn-not-come-status{
    background-color: #b5b5b5;
  }

  .track-title{
    width: 90%;
    h4{
      font-family: YekanBold;
      text-align: center;
      direction: rtl;
    }
    span{
      width: 100%;
      display: flex;
      border-radius: 12px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: YekanBold;
    }
  }

}

.book-status-sec{
  width: 90%;
  h4{
    font-family: YekanBold;
    text-align: center;
    direction: rtl;
  }
  span{
    width: 100%;
    display: flex;
    border-radius: 12px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: YekanBold;
  }
  p{
    width: 100%;
    display: flex;
    border-radius: 12px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    color: #c1c1c1;
    font-family: YekanRegular;
  }
}

.track-not-found{
  font-family: YekanBold;
}

.track-order-status{
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  width: 90%;
  margin: 12px 0;
  padding: 12px;
  border-radius: 12px;
  span{
    font-family: YekanBold
  }
  p{
    font-family: YekanMedium;
    margin-top: 12px;
    direction: rtl;
    text-align: center;
  }

}
