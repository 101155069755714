.dumbbell-header-container{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .dumbbell-header-item{
    position: relative;
    span{
      font-family: YekanBold;
      font-size: 18px;

    }
  }
  .notification-badge{
    background-color: #F86D6D;
    color: white;
    padding: 4px 4px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    position: absolute;
    top: -1px;
    left: 14px;
    justify-content: center;
    align-items: baseline;
  }
}

