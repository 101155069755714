.my-requests-page{
  padding: 20px 20px 10px 20px;
}
.dumbbell-booking-mg-booking-item{
  border: 1px solid #B6B6B6;
  margin: 10px;
  border-radius: 10px;
  background-color:#E2E1FF;
  padding: 10px 10px;
  .booking-turn-booked-status{
    background-color: transparent;
    border: 1px solid #8D89FD;
    color: #8D89FD;
  }
  .booking-turn-pending-status{
    background-color: transparent;
    border: 1px solid #fdbd76;
    color: #f69f41;
  }
  .booking-turn-cancel-me-status{
    background-color: transparent;
    border: 1px solid #FA8298;
    color: #d92f4d;
  }
  .booking-turn-cancel-client-status{
    background-color: transparent;
    border: 1px solid #FA8298;
    color: #d92f4d;
  }
  .booking-turn-accepted-status{
    background-color: transparent;
    border: 1px solid #B0B0BD;
    color: #B0B0BD;
  }
  .booking-turn-not-come-status{
    background-color: transparent;
    border: 1px solid #FF9031;
    color: #FF9031;
  }
  .dumbbell-booking-mg-booking-item-header{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 4px;
    font-family: YekanBold;
    font-size: 12px;
    .dumbbell-booking-mg-booking-item-header-reject:active{
      opacity:0.3
    }
    .dumbbell-booking-mg-booking-item-header-reject{
      cursor: pointer;
      background-color: #8f8f8f;
      font-family: YekanRegular;
      color: white;
      width: 100px;
      text-align: center;
      margin: 3px;
      border-radius: 4px;
    }
    .dumbbell-booking-mg-booking-item-header-approve:active{
      opacity: 0.3;
    }
    .dumbbell-booking-mg-booking-item-header-approve{
      cursor: pointer;
      background-color: #00BCD4;
      font-family: YekanRegular;
      color: white;
      width: 100px;
      text-align: center;
      margin: 3px;
      border-radius: 4px;
    }
  }

  .dumbbell-booking-mg-booking-item-body{

    .dumbbell-booking-mg-booking-item-body-info-row{
      display: flex;
      justify-content: space-between;
      margin: 10px 10px;
      .mobile-val{
        font-family: YekanBold;
      }
      .name-val{
        font-family: YekanBold;
      }
    }


    .dumbbell-booking-mg-booking-item-body-time-row{
      display:flex;
      justify-content: center;
      align-items: center;
      span{
        font-family: YekanBold;
        font-size:25px;
        margin: 0 4px;
      }
    }

    .dumbbell-booking-mg-booking-item-body-desc-row{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px 10px;
      span{
        font-family: YekanMedium;
        direction: rtl;
        color: gray;
      }
      p{
        font-family: YekanRegular;
        direction: rtl;
      }
    }


  }

}

.description-drawer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.description-drawer-input{
  width: 100%;
  textarea{
    border: 1px solid lightgray;
    padding: 8px;
    font-size: 14px;
    font-family: YekanMedium;
    border-radius: 4px;
    width: 100%;
    direction: rtl;
  }

}

.description-drawer-hint{
  font-family: YekanBold;
  display: flex;
  justify-content: center;
  p{
    text-align: center
  }
}

.description-drawer-btn{
  width: 100%;
  span{
    background-color: royalblue;
    color: white;
    font-family: YekanMedium;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.receipt-image{
  margin: 12px;
  img{
    width: 100%;
    border-radius: 12px;
  }
}
.plan-status-label{
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 4px;
  font-family: YekanBold;
  font-size: 12px;
}

.plan-is-end{
  background-color: transparent;
  border: 1px solid #b5b5b5;
  color: #b5b5b5;
}
.plan-wait-for-pay{
  background-color: transparent;
  border: 1px solid #8D89FD;
  color: #8D89FD;
}
.plan-wait-for-deliver{
  background-color: transparent;
  border: 1px solid #f3b345;
  color: #f3b345;
}
.plan-delivered{
  background-color: transparent;
  border: 1px solid #5dbfc9;
  color: #5dbfc9;
}
.plan-wait-for-accept{
  background-color: transparent;
  border: 1px solid #f39045;
  color: #f39045;
}
.plan-rejected{
  background-color: transparent;
  border: 1px solid #ed6e6f;
  color: #ed6e6f;
}

.booking-card-items-sec{
  margin-bottom:12px
}
