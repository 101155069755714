.admin-trainer-add-container{

}
.admin-trainer-add-form{
  margin: 20px 10px 0;
}
.admin-trainer-add-item{
  background-color: #F3F3F3;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    direction: rtl;
    font-family: YekanBold;
  }
  input{
    border: none;
    font-size: 15px;
    padding: 8px 4px;
    font-family: YekanRegular;
    background-color: transparent;
    text-align: center;
  }
}

.admin-trainer-add-btn{
  background-color: royalblue;
  color: white;
  margin: 10px 20px;
  border-radius: 8px;
  height: 40px;
  font-family: YekanRegular;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-trainer-detail-img{
  display: flex;
  justify-content: center;
  .trainer-image-wrapper{
    width: 80px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100px;
    }
  }


}

.admin-trainer-detail-info{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .admin-trainer-detail-name{
    font-family: YekanBold;
    font-size:16px
  }
  .admin-trainer-detail-mobile{
    font-family: YekanMedium;
  }
}

.admin-trainer-detail-statistic{
  margin: 14px 12px;
  background-color: #F3F3F3;
  border-radius:12px;
  .admin-trainer-detail-statistic-header{
    display: flex;
    justify-content: space-between;
    padding: 4px 24px;
    .admin-trainer-detail-statistic-average{
      font-family: YekanBold;
      font-size:20px;
    }
    .admin-trainer-detail-statistic-filter{
      select{
        border:none;
        background-color:transparent
      }
    }
  }
  .admin-trainer-detail-statistic-row{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e3e2e2;
    padding: 4px 24px;
    .admin-trainer-detail-statistic-row-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      h4{
        margin: 0;
        font-family: "YekanBold"
      }
      span{
        font-family: "YekanRegular"
      }
    }
  }

}

.admin-trainer-detail-resource{
  background-color: #CBC9FE;
  border-radius: 12px;
  display: flex;
  margin: 0 12px;
  justify-content: space-between;
  padding: 12px 24px;
  .admin-trainer-detail-resource-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    h5{
      margin: 0;
      font-family: YekanBold;
      font-size:17px
    }
    span{
      font-family: YekanRegular;
    }
  }
}


.admin-trainer-detail-services{
  background-color:#EEEEEE;
  border-radius: 12px;
  margin: 12px 12px;
  .admin-trainer-detail-services-row:last-child{
    border-top:none;
  }
  .admin-trainer-detail-services-row{
    border-bottom:1px solid lightgrey;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    font-family:YekanMedium;
    margin: 0 12px;
  }
}

.admin-trainer-detail-debt{
  background-color: #FDEBEB;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 0;
  margin: 0 12px;
  h5{
    margin: 0;
    padding: 0;
    font-family: YekanBold;
    font-size: 24px;
    direction: rtl;
  }
  span{
    direction: rtl;
    color: gray;
    font-family: YekanRegular;
    font-size: 13px;
  }
}

.admin-trainer-detail-send-welcome{
  background-color:#8A86FE;
  border-radius: 8px;
  padding:8px 12px;
  margin: 0 12px;
  display: flex;
  align-items:center;
  justify-content:center;
  span{
    color: white;
    font-family: YekanMedium
  }
}

.admin-trainer-detail-send-notification{
  background-color:#F3F3F3;
  border-radius: 8px;
  padding:8px 12px;
  margin: 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  textarea{
    border:none;
    border-radius: 4px;
    width: 100%;
  }
  span{
    font-family: YekanMedium;
    color: white;
    background-color: #8A86FE;
    width: 100%;
    margin: 8px;
    border-radius: 4px;
    text-align:center;
    padding:4px 4px
  }
}


.admin-trainer-detail-checkout{
  background-color:#F3F3F3;
  border-radius: 8px;
  padding:8px 12px;
  margin: 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  h5{
    direction: rtl;
    font-family:YekanBold;
    font-size:20px;
    margin: 0;
  }
  input{
    font-family:YekanMedium;
    border: none;
    border-radius: 4px;
    width: 100%;
    padding:8px 4px;
    text-align: center;
  }
  span{
    font-family: YekanMedium;
    color: white;
    background-color: #8A86FE;
    width: 100%;
    margin: 8px;
    border-radius: 4px;
    text-align:center;
    padding:4px 4px
  }
}
