.page-container-v2{
  padding-bottom: 20px;
}

.page-video-sec{
  position: relative;
  //background-color: black;
  margin: 10px 10px 0px;
  //border-radius: 12px;
 .player-sec{
   video{
     object-fit: cover !important;
     border-radius: 12px !important;
   }
 }
}

.page-actions-btns{
  display: flex;
  align-items: center;
  .page-actions-btn{
    margin: 10px;
    flex:1;
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 10px;
    .page-actions-btn-icon{
      flex: 2;
      padding: 0 6px ;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .page-actions-btn-label-left{
      flex: 10;
      text-align: center;
      //background-color: #FFCE31;
      padding: 8px;
      border-radius: 0 10px 10px 0;
      font-family: YekanRegular;
      color: #202020;
    }
    .page-actions-btn-label-right{
      flex: 10;
      text-align: center;
      //background-color: #202020;
      padding: 8px;
      border-radius: 10px 0 0 10px;
      font-family: YekanRegular;
      color: #202020;
    }
  }

}

.page-profile{
  margin: 15px 0;
  .page-profile-img{
    display: flex;
    justify-content: center;
    margin: 10px;
    .page-profile-img-default{
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 2px solid lightgrey;
    }

  }

  .page-profile-name{
    display: flex;
    align-items: center;
    flex-direction: column;
    h6{
      font-family: YekanBold;
      font-size: 18px;
      margin: 0;
      direction: rtl;
    }
    span{
      font-family: YekanRegular;
      direction: rtl;
      max-width: 60%;
      text-align: center;
    }
  }

}

.page-divider{
  hr{
    height: 1px;
    width: 85%;
    text-align: center;
    background-color: #f5f5f5;
    display: block;
    border: 0;
    border-top: 1px solid #f5f5f5;
  }
}

.page-divider-banner{
  hr{
    height: 1px;
    width: 85%;
    text-align: center;
    display: block;
    border: 0;
  }
}

.page-desc-items{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .page-desc-item{
    display: flex;
    align-items: center;
    margin: 5px 20px;
    .page-desc-item-bullet{
      width: 20px;
      height: 20px;
      background-color: #FFCE31;
      border-radius: 4px;
      margin: 0 5px;
    }
    .page-desc-item-text-p{
      font-family: YekanBold;
      font-size: 13px;
    }
  }
}

.page-socials{
  padding:14px 16px;
  border-radius: 5px;
  margin: 15px 20px;
}

.social-bar-item{
  position: relative;
  width:60px;
  background-position: center;
  background-repeat: no-repeat;
  font-family: YekanRegular;
  cursor: pointer;
  color: #afafaf;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 5px;
}

.page-phone{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  text-align: center;
  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
}

.page-email{
  display: flex;
  justify-content: center;
  align-items: center;
  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
}

.page-address{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 10%;
  text-align: center;


  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span:last-child{
    border: none;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
    border-bottom: 1px solid lightgrey;
    margin: 2px 0;
  }
}

.page-location{
  display: flex;
  justify-content: center;
  .page-actions-btn{
    margin: 10px;
    width: 50vw;
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 10px;
    .page-actions-btn-icon{
      flex: 2;
      padding: 0 6px ;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .page-actions-btn-label-left{
      flex: 10;
      text-align: center;
      background-color: #f5f5f5;
      padding: 8px;
      border-radius: 0 10px 10px 0;
      font-family: YekanRegular;
      color: black;
    }
    .page-actions-btn-label-right{
      flex: 10;
      text-align: center;
      background-color: #f5f5f5 !important;
      padding: 8px;
      border-radius: 10px 0 0 10px;
      font-family: YekanRegular;
      color: black !important;
    }
  }

}

.page-followup{
  display: flex;
  align-items: center;
  justify-content: center;
  .page-followup-btn{
    background-color: #EEEEEE;
    border:1px solid #D2D2D2;
    color: #F17676;
    font-family: YekanRegular;
    width: 60vw;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 12px;
    span{
      margin: 0 10px;
    }
  }
}

.imag-thumb{
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid lightgrey;
  .user_img{
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
}
.page_bullet{
  width: 15px;
  height: 15px;
  background-color: #8aa6f3;
  border-radius: 4px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.package-card{
  background-color: #F8F8FF;
  border:1px solid #BCB7B7;
  margin: 10px 20px;
  border-radius: 8px;
  padding: 15px 20px;

  .package-card-header{
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #D0D0D0;
    h6{
      font-family: YekanHeavy;
      font-size: 14px;
    }
    img{
      position: absolute;
      left: 0px;
      top: -5px;
      background-color: #ffcc67;
      padding: 5px;
      border-radius: 4px;
      margin: 0 5px;
    }
  }

  .package-card-des{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 5px;
    .package-card-des-item{
      display: flex;
      align-items: center;
      justify-content:flex-end ;
      margin: 2px 0;
      .package-card-des-item-label{
        font-family: YekanRegular;
        direction: rtl;
      }
      .package-card-des-item-bullet{
        width:15px;
        height:15px;
        border-radius: 20px;
        background-color: #C2B9F2;
        margin: 0 5px;
      }
    }

  }

  .package-card-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    span{
      direction: rtl;
    }
    .package-card-pay_amount{
      font-family: YekanHeavy;
      color: #696868;
      font-size: 20px;
    }
    .package-card-amount{
      font-family: YekanRegular;
      color: #A8A8A8;
      font-size: 13px;
      text-decoration: line-through;
    }
    .package-card-extra_label{
      font-family: YekanRegular;
      font-size: 13px;
      color:#8D89FD
    }
  }
  .package-card-btn{
    display: flex;
    justify-content: center;
    span{
      width: 50vw;
      padding: 8px 10px;
      border-radius: 4px;
      font-family: YekanRegular;
      color: white;
      background-color: #8D89FD;
      text-align: center;
    }
  }

}

.not-found-page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:100vh;
  img{

  }
  span{
    font-family:'YekanBold';
    font-size: 16px;

  }
}

.default-page{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 100vh;
  span{
    margin-top:200px
  }
}

.swiper-page-wrapper{
  margin: 0 20px;
}

.page-shop{
  margin: 0 0;
  padding: 5px 0;
  border-radius: 8px;
  .page-shop-btn{
    display: flex;
    justify-content: center;
    span{
      background-color: #ff7171;
      color: white;
      font-family: YekanRegular;
      padding: 4px;
      width: 180px;
      border-radius: 4px;
      text-align: center;
    }
  }
  .page-shop-description{
    display: flex;
    justify-content: center;
    p{
      font-family: YekanBold;
      text-align: center;
      margin: 5px 0;
    }
  }
}
.gallery_wrapper>div{
  width: 100%;
}


.imag-thumb-free{
  .user_img-free{

  }
}

.link-wrapper{
  width:70px !important;
  height: 70px !important;

}

.package-card-image{
  img{
    width: 100%;
    border-radius: 8px;
  }
}

.subs-card-header{
  margin: 4px 8px -8px;
}

.subs-drawer-header{
   display: flex;
   justify-content: center;
   padding: 20px 20px 6px;
   font-family: 'YekanBold';
   border-bottom: 1px solid lightgray;
   margin: 0 20px 15px;
 }

.discount-badge-quick{
  position: absolute;
  right: 43px;
  top: 22px;
  z-index: 10;
  background-color: orange;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: YekanBold;
  font-size: 10px;

}
