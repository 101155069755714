.plan-list-header{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px 5px;
  margin: 10px 20px;
  border-radius: 8px;
  .plan-list-header-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0  10px;
    .name{
      font-family: YekanBold;
    }
    .mobile{
      font-family: YekanThin;
      font-size: 13px;
    }
  }
  .plan-list-header-image{
    position: absolute;
    left: 20px;
    top: 15px;
    img{
      width: 80px;
    }
  }
}

.plan-list-trainee{

  margin: 20px 20px 10px 20px;



}



.plan-card-trainer-image{
  position: absolute;
  right: 5px;
  top: -20px;

}

.trainee-status{
  margin-right:70px;
}

.plan-card-list-trainee{
  position: relative;
  margin: 20px 0;
  background-color: #E2E1FF;
  border-radius: 12px;
  .plan-card-header{
    border-radius: 8px 8px 0 0;
    background-color: #8d89fd;
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    align-items: center;
    img{
      padding: 5px;
      cursor: pointer;
    }
    img:active{
      opacity: 0.3;
    }
    span{
      direction: rtl;
      font-family: YekanBold;
      color: white;
      font-size: 13px;
    }
  }

  .plan-card-body{
    //background-color: white;
    border-radius: 0 0 8px 8px;

    .plan-card-progress{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;
      .plan-card-progress-station{
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #D9D9D9;
        .plan-card-progress-station-date{
          position: absolute;
          top: -22px;
          left:-20px;
          width: 50px;
          font-family: YekanBold;
          direction: rtl;
        }
        .plan-card-progress-station-label{
          position: absolute;
          bottom: -25px;
          width: 50px;
          font-family: YekanRegular;
          color: #9F9F9F;
        }

      }
      .plan-card-progress-line{
        width: 100px;
        height: 5px;
        background-color: #D9D9D9;
      }
    }

    .plan-card-footer{
      display: flex;
      justify-content: center;

      padding: 10px 20px;
      .plan-card-footer-btn{
        span{
          font-family: YekanBold;
          color: white;
          background-color: #8D89FD;
          border-radius: 5px;
          padding: 4px 20px;
          min-width: 200px;
          text-align: center;
        }
        span:active{
          opacity: 0.3;
        }
      }

      .plan-card-footer-price{
        direction: rtl;
        span{
          font-family: YekanBold;
        }

      }
    }

  }

}
.trainer-name-trainee{
  font-family: YekanBold;
}