.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* PAGES */
.page-container {
    width: 100%;
    height: 100%;
    font-family: Oxygen, sans-serif;
}

.inner-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

/* Button */
.btn {
    border: 0;
    border-radius: 3px;
    padding: 5px;
    font-size: 11px;
    background-color: #3498db;
    color: #fff;
    box-shadow: 0 0 13px 2px rgba(15, 15, 15, 0.2);
    margin: 10px 0;
    cursor: pointer;
}

