.upload-loading-component{
  position: fixed;
  background-color: #000000c7;
  width: 100%;
  height: 100%;
  z-index: 1000000000000000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .upload-loading-sec{
    width: 100%;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    .upload-loading-title{
      font-family: YekanBold;
      color: white;
      direction: rtl;
      margin: 10px 0;
    }
    .upload-loading-percent{
      font-family: YekanBold;
      color: white;
      direction: rtl;
      font-size: 20px;
    }
    p{
      font-family: YekanRegular;
      color: white;
      font-size: 13px;
      direction: rtl;
    }
  }
}



.step-loader {
  width: 64px;
  height: 64px;
  position: relative;
  background: #FFF;
  border-radius: 4px;
  overflow: hidden;
}
.step-loader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  transform: rotate(45deg) translate(30%, 40%);
  background: #5c7bb1;
  box-shadow: 32px -34px 0 5px #444efd;
  animation: slide 2s infinite ease-in-out alternate;
}
.step-loader:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0055ff;
  transform: rotate(0deg);
  transform-origin: 35px 145px;
  animation: rotate 2s infinite ease-in-out;
}

@keyframes slide {
  0% , 100%{ bottom: -35px}
  25% , 75%{ bottom: -2px}
  20% , 80%{ bottom: 2px}
}
@keyframes rotate {
  0% { transform: rotate(-15deg)}
  25% , 75%{ transform: rotate(0deg)}
  100% {  transform: rotate(25deg)}
}
  