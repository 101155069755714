.dumbbell-land-container{
  .dumbbell-land-image{
    img{
      width: 100%;
    }
  }
}



.dumbbell-land-trainer-desc-item{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 20px;
  .item-text{
    font-family: YekanRegular;
    margin: 0 10px;
  }
  .item-bullet{
    display: block;
    width: 10px !important;
    height: 10px !important;
    background-color:#8D89FD
  }
}

.ant-collapse-header{
  margin: 0 !important;
  padding: 0 !important
}

.ant-collapse > .ant-collapse-item{
  border: none !important;
}
.ant-collapse-content{
  border: none;
}
.ant-collapse{
  background-color:white;
  border: none !important;
}
.dumbbell-land-plan-item{
  background-color:#8D89FD;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  margin: 10px;
  border-radius:5px;
  .price{
    color: white;
    font-family: YekanBold;
    direction: rtl;
  }
  .title{
    font-family: YekanBold;
  }
}
.dumbbell-land-plan-item:active{
  opacity: 0.3;
}

.dumbbell-land-plan-desc-item{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 14px;
  .desc-text{
    font-family: YekanBold;
    margin: 0 10px;
  }
  .desc-bullet{
    display: block;
    width: 14px !important;
    height: 14px !important;
    border-radius: 50px;
    background-color:#FFCE31
  }
}

.dumbbell-land-plan-desc-btn{
  display: flex;
  justify-content: center;
  span{
    background-color:#B667E5;
    color: white;
    font-family: YekanBold;
    padding: 4px 10px;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    cursor: pointer;
  }
  span:active{
    opacity:0.3
  }
}

.dumbbell-land-name{
  display: flex;
  justify-content: flex-end;
  margin: 15px;
  span{
    font-family: YekanBold;
    font-size: 15px;
  }
}


.dumbbell-invoice-page-form{
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  margin: 10px;

  .dumbbell-invoice-page-form-row:last-child{
    border: none;
  }
  .dumbbell-invoice-page-form-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding: 10px 15px;
    .dumbbell-invoice-page-form-label{
      font-family: YekanRegular;
    }
    .dumbbell-invoice-page-form-value{
      font-family: YekanBold;
      direction: rtl;
    }
  }

}

.dumbbell-invoice-page-payable{
  display: flex;
  align-items: center;
  justify-content: space-around;
  .dumbbell-invoice-page-payable-value{
    font-family: YekanBold;
    direction: rtl;
    color: #7789e3;
    font-size: 25px;
  }
  .dumbbell-invoice-page-payable-label{
    font-family: YekanRegular;
    direction: rtl;
    color: #7789e3;
  }
}

.dual-btns{
  display: flex;
  align-items:center;
  justify-content: space-around;
  .train-btn{
    background-color: #7789e3;
    color: white;
  }
  .booking-btn{
    background-color: #ffcb64;
  }
  .dual-btn{
    flex:1;
    border-radius: 5px;
    padding: 4px 18px;
    margin: 0 10px;
    text-align: center;
    span{
      font-family:YekanBold
    }
  }
}
.social-wrapper{
  padding: 10px;
}
.social-list{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .social-item{
    background-color:lightgray;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.muted-sec{
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: #8D89FD;
  color: white;
  padding: 8px;
  border-radius: 10px;
  font-size: 20px;
}