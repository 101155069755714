.attr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attr-input {
  font-size: 2rem;
  padding: 10px;
  width: 100%;
  text-align: center;
  height: 54px;
}

.attr-numLock {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* سه ستون */
  gap: 10px;
  margin: 16px;
}

.attr-numLock-item {
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  user-select: none;
  font-size: 16px;
  font-family: YekanBold;
}

.attr-numLock-item:hover {
  background-color: #ddd;
}

.attr-enter {
  padding: 10px 20px;
  background-color: #8d89fd;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  width: 90%;
  text-align: center;
}

.attr-enter:hover {
  background-color: #8d89fd;
}
