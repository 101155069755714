.page-container-v2{
  padding-bottom: 20px;
}

.page-video-edit-sec{
  min-height: 200px;
  background-color: gray;
  border-radius: 12px;
}
.page-video-sec{
  position: relative;
  //background-color: black;
  margin: 10px 10px 0px;
  //border-radius: 12px;
 .player-sec{
   //height: 180px;
   video{
     object-fit: cover !important;
     border-radius: 12px !important;
   }
 }
}

.page-actions-btns{
  display: flex;
  align-items: center;
  .page-actions-btn{
    margin: 10px;
    flex:1;
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 10px;
    .page-actions-btn-icon{
      flex: 2;
      padding: 0 6px ;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .page-actions-btn-label-left{
      flex: 10;
      text-align: center;
      //background-color: #FFCE31;
      padding: 8px;
      border-radius: 0 10px 10px 0;
      font-family: YekanRegular;
      color: #202020;
    }
    .page-actions-btn-label-right{
      flex: 10;
      text-align: center;
      //background-color: #202020;
      padding: 8px;
      border-radius: 10px 0 0 10px;
      font-family: YekanRegular;
      color: #202020;
    }
  }

}

.page-profile{
  margin: 15px 0;
  .page-profile-img{
    display: flex;
    justify-content: center;
    margin: 10px;
    .page-profile-img-default{
      position: relative;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 2px solid lightgrey;
    }

  }

  .page-profile-name{
    display: flex;
    align-items: center;
    flex-direction: column;
    h6{
      font-family: YekanBold;
      font-size: 18px;
      margin: 0;
      direction: rtl;
    }
    span{
      font-family: YekanRegular;
      direction: rtl;
    }
  }

}

.page-divider{
  hr{
    height: 1px;
    width: 85%;
    text-align: center;
    background-color: #f5f5f5;
    display: block;
    border: 0;
    border-top: 1px solid #f5f5f5;
  }
}

.page-desc-items{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .page-desc-item{
    display: flex;
    align-items: center;
    margin: 5px 8px;
    .page-desc-item-bullet{
      width: 25px;
      height: 26px;
      background-color: #e9e9e9;
      border-radius: 4px;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
    }
    .page-desc-item-text{
      font-family: YekanRegular;
      font-size: 13px;
    }
  }
}

.page-socials{
  padding:14px 16px;
  border-radius: 5px;
  margin: 15px 20px;
}

.social-bar-item{
  position: relative;
  width:60px;
  background-position: center;
  background-repeat: no-repeat;
  font-family: YekanRegular;
  cursor: pointer;
  color: #afafaf;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 5px;
}

.page-phone{
  display: flex;
  justify-content: center;
  align-items: center;
  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
}

.page-email{
  display: flex;
  justify-content: center;
  align-items: center;
  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
}

.page-address{
  display: flex;
  justify-content: center;
  align-items: center;
  h6{
    margin: 0;
    font-family: YekanBold;
    direction: rtl;
    font-size: 14px;
  }
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
}

.page-location{
  display: flex;
  justify-content: center;
}


.page-followup{
  display: flex;
  align-items: center;
  justify-content: center;
  .page-followup-btn{
    background-color: #EEEEEE;
    border:1px solid #D2D2D2;
    color: #F17676;
    font-family: YekanRegular;
    width: 60vw;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 12px;
    span{
      margin: 0 10px;
    }
  }
}

.edit-icon{
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-progress{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
  background-color: white;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span{
    font-family: YekanBold;
    font-size: 13px;
  }
}

.upload-btn{
  position: absolute;
  top: 10px;
  left: 47px;
  background-color: white;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span{
    color: #8D89FD;
    font-family: YekanBold;
    font-size: 13px;
  }


}

.edit-thumb-icon{
  position: absolute;
  top: 0px;
  left: -9px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-profile-name{
  input{
    width: 60%;
    border:1px solid lightgrey;
    border-radius: 7px;
    font-family: YekanRegular;
    text-align: center;
    margin: 5px 0;
    direction: rtl;
    padding: 3px;
  }
}

.page-social-item{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  .input-sec{
    width: 60%;
    border: 1px solid lightgrey;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    input{
      direction: rtl;
      width: 80%;
      font-family: YekanRegular;
      text-align: center;
      border: none;
      padding: 4px;
      outline: none;
    }
  }
  h6{
    font-size: 13px;
    direction: rtl;
    font-family: YekanRegular;
    margin: 0 5px;
    width: 80px;
    img{
    }
  }

}

.page-save-btn{
  display: flex;
  justify-content: center;
  margin: 20px 0;
  span{
    background-color: #7A75FF;
    color: white;
    font-family: YekanRegular;
    width: 50%;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
  }
}

.imag-thumb{
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid lightgrey;
  .user_img{
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: cover;
  }
}

.upload_image_btn{
  position: absolute;
  top: 0px;
  left: -93px;
  background-color: white;
  padding: 2px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid lightgray;
  span{
    color: #8D89FD;
    font-family: YekanBold;
    font-size: 13px;
  }
}

.view-page-float{
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: rgba(141, 137, 253, 0.86);
  border-radius: 5px;
  padding: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  z-index: 10;
}
.active-page{
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
  h6{
    font-family: YekanBold;
    font-size: 13px;
    margin: 1px 10px 0;
  }
}

.page-swiper-item{
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .img-wrapper{
    border-radius: 4px;
    border:1px solid #d2d2d2;
    background-color: #f5f5f5;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;

    }
    .default-icon{
      span{
        color: #e2e2e2;
        font-size: 30px;
      }
    }
  }
  span{
    font-family: YekanRegular;
    font-size:15px;
    margin: 5px 0;
  }
}

.page-sec{
  margin: 10px 30px;
  direction: rtl;
}

.page-sec-header{
  margin: 10px;
  font-family: YekanBold;
  display: flex;
  align-items: center;
  input{
    width: 20px;
    height: 20px;
  }
  span{
    margin: 0 10px;
  }
}
.page-actions-btn{
  margin: 10px;
  width: 50vw;
  display: flex;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 10px;
  .page-actions-btn-icon{
    flex: 2;
    padding: 0 6px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-actions-btn-label-left{
    flex: 10;
    text-align: center;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 0 10px 10px 0;
    font-family: YekanRegular;
    color: black;
  }
  .page-actions-btn-label-right{
    flex: 10;
    text-align: center;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 10px 0 0 10px;
    font-family: YekanRegular;
    color: black;
  }
}
.page-sec-body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  direction: ltr;
}

.link-drawer-section{

}
.link-drawer-header{

}

.link-drawer-body{
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-drawer-image{
    position: relative;
    margin: 10px 0;
    .link-edit-image{
      position: absolute;
      background-color: #ffffff;
      top: 0;
      left: 0;
      padding: 6px;
      border-radius: 4px;
      border: 1px solid lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .link-delete-image{
      position: absolute;
      background-color: #ffffff;
      top: 0;
      right: 0;
      padding: 6px;
      border-radius: 4px;
      border: 1px solid lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 16px;
      }
    }
    .link-main-image{
      width: 100px;
      height: 100px;
      border-radius: 12px;
      img{
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .default-image{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid #d2d2d2;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #e2e2e2;
  }
  .link-title-input{
      border: 1px solid lightgrey;
      border-radius: 4px;
      width: 250px;
      padding: 5px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-family: YekanRegular;
    margin: 5px 0;
  }
  .link-submit-btn{
    background-color: royalblue;
    color: white;
    font-family: YekanRegular;
    padding: 5px 10px;
    border-radius: 8px;
    width: 250px;
    margin: 10px;
    text-align: center;
  }
}
.link-social-select{
  border: 1px solid lightgrey;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  outline: none;
  text-align: center;
  font-size: 16px;
  font-family: YekanRegular;
  margin: 5px 0;
}
.link-title{
  direction: ltr;
}
.osm-sec{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  .accept-location{
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10000;
    span{
      background-color: #8d89fd;
      color: white;
      font-family: YekanRegular;
      padding: 4px 12px;
      border-radius: 8px;
      width: 159px;
      display: block;
      text-align: center;
    }
  }
  .osm-custom-flag{
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    img{
      width:100%
    }
  }
}

.shop-sec{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  input{
    width: 60%;
    border: 1px solid lightgrey;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2px 0;
    padding: 6px;
    text-align: center;
    outline: none;
  }
  textarea{
    outline: none;
    text-align: center;
    width: 60%;
    border: 1px solid lightgrey;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6px;
    margin: 2px 0;
  }
}


.page-swiper-gallery-item{
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .img-wrapper{
    border-radius: 100px;
    border:1px solid #d2d2d2;
    background-color: #f5f5f5;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100px;

    }
    .default-icon{
      span{
        color: #e2e2e2;
        font-size: 30px;
      }
    }
  }
  span{
    font-family: YekanRegular;
    font-size:15px;
    margin: 5px 0;
  }
}


//products

.product-swiper-product-card-wrapper {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right:26px;
}

.product-swiper-product-card{
  background-color:lightgray;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .product-swiper-product-card-image{
    width: 100px;
    height: 100px;
    margin: 10px;
    border-radius: 8px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .product-swiper-product-card-price{
    height: 15px;
    text-align: center;
    span{
      font-family: YekanBold;
      font-size: 15px;
    }
  }
  .product-swiper-product-card-discount{
    height: 20px;
    text-align: center;
    span{
      font-family: YekanBold;
      font-size: 12px;
      text-decoration: line-through;
      color: red;
    }
  }

  .product-swiper-product-card-name{
    font-family: YekanBold;
    font-size: 13px;
    padding: 5px 0 8px;
    color: gray;
    text-align: center;
    height: 50px;
    margin: 0 5px;
    direction: rtl;
  }

  .product-swiper-product-card-add-btn{
    padding-bottom: 15px;
    img{
      width:25px
    }
  }

}

.image-swiper-placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  .anticon{
    font-size:30px;
    color: gray;
  }
}


.inPerson-page{
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;
  h6{
    font-family: YekanBold;
    font-size: 13px;
    margin: 1px 10px 0;
  }
}

.img-banner-sec{
  display: flex;
  justify-content: center;
}
.img-banner-file{
  width: 100%;
  margin: 12px 24px;
  border-radius: 12px;
}

.banner-file-btn{
  display: flex;
  justify-content: flex-end;
  .choose{
    background-color: #bfb8fd;
    padding: 4px 12px;
    border-radius: 8px;
    margin: 4px 0;
    font-family: YekanBold;
    color: white;
  }
  .upload{
    background-color: #6657ee;
    padding: 4px 12px;
    border-radius: 8px;
    margin: 4px 0;
    font-family: YekanBold;
    color: white;
  }
  .delete{
    background-color: #ef6464;
    padding: 4px 12px;
    border-radius: 8px;
    margin: 4px 0;
    font-family: YekanBold;
    color: white;
  }
}


.banner-file-btn-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  .banner-file-btn{
    margin: 4px 12px;
  }

}

.pay-row{
  display: flex;
  align-items: center;
  margin: 12px 4px;
  span{
    font-family: YekanBold;
    margin: 0 4px;
  }
}
