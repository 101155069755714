.active-tabs{
  background-color:#8D89FD !important;
  span{
    color: white !important;
  }
}


.dumbbell-trainee-card-send{
  padding: 5px 10px;
  border-radius: 12px;
  background-color:#f3f3f3;
  margin: 10px 30px;
  position: relative;
}
.card-send-btn{
  position: absolute;
  background-color:#8D89FD;
  top: 0;
  width: 50px;
  height: 100%;
  left: 0;
  border-radius: 12px 0 0 12px;
  display: flex;
  justify-content:center;
  align-items:center;
  color: white;
  font-size: 12px;
}
.dumbbell-trainee-card-send-drawer{
  display: flex;
  justify-content:flex-end;
  align-items:center;
  width: 100%;
  border-radius: 12px;
}
.dumbbell-trainee-card-trainee-info-send{
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  margin: 10px 10px;
}

.dumbbell-trainee-card-drawer-info-name-send{
  font-family:YekanBold;
}
.dumbbell-trainee-card-drawer-info-mobile-send{
  font-family:YekanMedium;
}
.dumbbell-trainee-card-trainee-thumb-send{
  img{
    width: 60px;
    border-radius: 100px;
  }
}


.send-drawer-new-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  .send-drawer-new-form-item{
    width: 100%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span{
      font-family: YekanBold;
      width: 250px;
      direction:rtl;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin: 5px 0;
    }
    input[type='text'] {
      width: 100%;
      border-radius: 8px;
      border:1px solid lightgrey;
      text-align: center;
      font-size: 14px;
      font-family: YekanRegular;
      padding: 10px;
      outline: none;
    }
    button{
      width: 100%;
      border-radius: 8px;
      text-align: center;
      font-size: 14px;
      font-family: YekanRegular;
      padding: 10px;
      border: none;
      background-color: #8D89FD;
      color:white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.send-drawer-trainees{
  margin: 30px 0;
}
