.recorder-custom{
  width: 100px;
  border-radius: 2px;
}
.play-section{
  display: flex;
  align-items: center;
  .anticon{
    font-size: 18px;
    margin-left: 5px;
  }
}