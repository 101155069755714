.discount-system-body{
  margin: 4px 24px;
  .search-row{
    margin:0 6px;
  }
}
.ant-select-selector{
  background-color: #ebebeb !important;
  border: none !important;
  border-radius: 4px !important;
}

.campaign-card{
  border: 1px solid #B6B6B6;
  margin: 10px;
  border-radius: 10px;
  background-color: #E2E1FF;
  padding: 10px 10px;
  .campaign-card-title{
    display: flex;
    justify-content: flex-start;
    font-family: YekanBold;
    direction: rtl;
    font-size: 16px;
  }
  .campaign-card-type{
    display: flex;
    justify-content: flex-start;
    font-family: YekanRegular;
    direction: rtl;
    font-size: 14px;
  }

  .campaign-card-footer{
    display: flex;
    justify-content: space-between;
    .campaign-card-footer-actions{
      display: flex;
      align-items: center;
      .discount-trash-btn{
        margin: 0 12px;
          background-color: orange;
          border-radius:4px;
          padding: 5px 6px;

          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 14px;
            height: 14px;
          }

      }
    }
    .campaign-card-date{
      display: flex;
      align-items: center;
      font-family: YekanRegular;
      span{
        margin: 0 4px;
      }
    }
    .campaign-card-status-active{
      background-color: royalblue;
      color: white;
    }
    .campaign-card-status-deActive{
      background-color: #acacac;
      color: white;
    }
    .campaign-card-status{
      font-family: YekanMedium;
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 12px;
    }
  }
}

.code-list-btn{
  background-color: #3f9acf !important;
}

.header-codes{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-bottom: 1px solid lightgrey;
  margin: 2px 20px 12px;
  span{
    font-family:YekanBold
  }
}

.generate-codes{
  display: flex;
  align-items: center;
  background-color: lightgrey;
  margin: 2px 20px;
  padding: 8px 8px;
  border-radius: 8px;
  input{
    border: none;
    flex:8;
    border-radius: 4px;
    text-align: center;
  }
  span{
    flex:4;
    text-align: center;
    font-family: YekanMedium;
    background-color: #2f54eb;
    color: white;
    margin: 0 4px;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px
  }
}

.list-codes{
  margin: 12px 20px;
}

.codes-card{
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 12px;
  margin: 6px 0;
  .codes-card-val{
    display: flex;
    justify-content: center;
    padding:2px 8px;
    span{
      font-size: 18px;
      font-weight: bold;
    }
  }

  .codes-card-footer{
    display: flex;
    justify-content: space-between;
    label{
      font-family:YekanMedium;
      font-size:13px;
    }
    .code-copy{
      background-color:#2196F3;
      font-family:YekanMedium;
      color: white;
      border-radius:4px;
      padding: 2px 8px;
      margin: 0 4px;
      font-size: 12px;
    }
    .trash-icon-sec{
      background-color: orange;
      border-radius:4px;
      padding: 5px 6px;
      margin: 0 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
      }
    }

  }
}
.footer-actions{
  display: flex;
  align-items: center;
  .code-copy{

  }
}

.discount-edit-btn{
  background-color:#2196F3;
  font-family:YekanMedium;
  color: white;
  border-radius:6px;
  padding: 4px 8px;
  margin: 0 4px;
  font-size: 12px;
}
