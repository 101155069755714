.dumbbell-login-container{

  .dumbbell-login-image{
    display: flex;
    justify-content: center;
    margin: 60px 0 0 0;
  }

  .dumbbell-login-logo{
    display: flex;
    justify-content: center;
    margin: 5px 0 0 0;
  }

  .dumbbell-login-input-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    .dumbbell-login-input{
      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 7px 12px;
      border-radius:8px;
      input{
        width: 200px;
        text-align: center;
        font-family: YekanRegular;
        outline:none;
        border: none;
        font-size: 15px;
      }
    }
  }

  .dumbbell-login-btn{
    margin: 20px 0 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      position: relative;
      background-color:#8D89FD;
      color: white;
      border-radius:8px;
      width:255px !important;
      text-align: center;
      padding:8px 10px;
      font-family: YekanRegular;
      cursor: pointer;
    }
    span:active{
      opacity:0.3
    }
  }

  .dumbbell-login-copyright{
    position:absolute;
    bottom:10px;
    text-align: center;
    width:100%;
    font-size:12px
  }

}


.dumbbell-login-return-link{
  display:flex;
  justify-content:center;
  position:absolute;
  bottom:-24px;
  font-family: YekanRegular;
  font-size:12px;
  color: #3e3e3e;
  cursor:pointer;
}
.dumbbell-login-return-link:active{
  opacity:0.3
}

.dumbbell-welcome{

  display: flex;
  flex-direction: column;
  align-items: center;
  .dumbbell-welcome-name{
    color: white;
    font-family:YekanBold;
    font-size: 18px;
  }

  .dumbbell-welcome-label{
    color: white;
    font-family:YekanRegular;
    font-size: 15px;
  }

  .dumbbell-welcome-message{
    color: white;
    font-family:YekanRegular;
    font-size: 15px;
  }

  .dumbbell-welcome-btn{
    position: relative;
    background-color: #8D89FD;
    color: white;
    border-radius: 8px;
    width: 255px !important;
    text-align: center;
    padding: 8px 10px;
    font-family: YekanRegular;
    cursor: pointer;
  }

}

.step-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 0 20px;
  h3{
    font-size: 50px;
    margin-bottom: 10px;
    letter-spacing: 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }

}

.dumbbell-welcome-v2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dumbbell-welcome-name{
  font-family: YekanBold;
  margin: 5px 0;
}

.step-trainer-image{
  display: flex;
  justify-content: center;
  .step-trainer-image-wrapper{
    width: 80px;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 2px solid lightgrey;
  }
}
.dumbbell-welcome-label{
  font-family: YekanBold;
  font-size: 18px;
}

.dumbbell-welcome-message{
  font-family: YekanRegular;
  text-align: center;
  margin: 0 30px;
}

.dumbbell-welcome-btn{
  position: relative;
  background-color: #8D89FD;
  color: white;
  border-radius: 8px;
  width: 255px !important;
  text-align: center;
  padding: 8px 10px;
  font-family: YekanRegular;
  cursor: pointer;
  margin: 15px 0;
}