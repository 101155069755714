.dumbbell-land-create-container{

  .dumbbell-land-create-btns{
    background-color:#DDDCF0;
    display: flex;
    justify-content:space-between;
    padding: 10px;
    .save{
      background-color:#BAB8FA;
    }
    .link{
      background-color:#B667E5;
    }
    .dumbbell-land-create-btn:active{
      opacity:0.3
    }
    .dumbbell-land-create-btn{
      width: 120px;
      cursor: pointer;
      border-radius: 5px;
      padding:2px 10px;
      display: flex;
      justify-content:center;
      align-items:center;
      span{
        font-family:YekanRegular;
        color: white;
      }
    }
  }




  .dumbbell-land-create-image-upload{
    background-color: #FAF8F8;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding: 40px;
    cursor:pointer;
    span{
      font-family:YekanRegular;
      margin-top: 8px;
    }
  }

  .dumbbell-land-create-image-message{
    display: flex;
    justify-content:center;
    span{
      color: #FF7B31;
      font-family:YekanRegular;
      margin-top: 5px;
    }
  }


  .dumbbell-land-create-divider{
    width: 100%;
    padding: 25px;
    .dumbbell-land-create-divider-line{
      height: 1px;
      background-color:lightgray;
    }
  }



  .dumbbell-land-create-info-sec{
    margin: 10px 20px;
    .dumbbell-land-create-info-add-input{
      display: flex;
      align-items: center;
      background-color: #F6F5F5;
      margin: 15px 0;
      padding: 8px;
      border-radius: 5px;
      input{
        width: 100%;
        text-align: right;
        direction:rtl;
        border:none;
        background-color:transparent;
        outline: none;
        font-family:YekanRegular
      }
      img{
        padding: 0 10px;
        cursor:pointer;
      }
      img:active{
        opacity:0.3
      }
    }


    .dumbbell-land-create-info-add-item{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 5px;
      .item-text{
        font-family: YekanRegular;
        margin: 0 10px;
      }
      .item-bullet:active{
        opacity: 0.3;
      }
      .item-bullet{
        display: block;
        padding: 10px;
        border-radius: 4px;
        background-color:#8D89FD;
        cursor: pointer;
      }
    }

  }

  .dumbbell-land-create-plan-sec{
    padding-bottom: 60px
  }


  .dumbbell-land-create-plan-card{
    position:relative;
    margin: 15px;
    background-color:#F5F3F3;
    border-radius: 0 0 8px 8px;
    .dumbbell-land-create-plan-card-header{
      width: 100%;
      background-color:#8D89FD;
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: center;
      padding: 15px 10px;
      input{
        font-family:YekanBold;
        border-radius:5px;
        padding: 3px;
        border: none;
        text-align: center;
        width: 250px;
        direction: rtl;

      }
    }

    .dumbbell-land-create-plan-card-main{
      padding:10px 5px;
      .dumbbell-land-create-plan-card-deliver-time{
        background-color: white;
        display: flex;
        justify-content: center;
        margin: 10px;
        border-radius:5px;
        input{
          border: none;
          border-radius:5px;
          font-family:YekanBold;
          direction:rtl;
          text-align: right;
          width: 100%;
          padding: 5px 10px;
          outline: none;
        }
      }


      .dumbbell-land-create-plan-card-desc-input{
        background-color: white;
        display: flex;
        justify-content: center;
        margin: 10px;
        border-radius:5px;
        input{
          border: none;
          border-radius:5px;
          font-family:YekanBold;
          direction:rtl;
          text-align: right;
          width: 100%;
          padding: 5px 10px;
          outline: none;
        }
        img{
          cursor: pointer;
          padding: 10px;
        }
        img:active{
          opacity:0.3
        }
      }

      .dumbbell-land-create-plan-card-desc-list{
        .dumbbell-land-create-plan-card-desc-item{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 10px 14px;
          .desc-text{
            font-family: YekanRegular;
            margin: 0 10px;
          }
          .desc-bullet:active{
            opacity: 0.3;
          }
          .desc-bullet{
            display: block;
            padding: 10px;
            border-radius: 4px;
            cursor: pointer;
            background-color:#FFCE31
          }
        }
      }



    }

    .dumbbell-land-create-plan-card-plus-badg{
      position: absolute;
      width:40px;
      height: 40px;
      background-color: #F5F3F3;
      bottom: -34px;
      display: flex;
      align-items:center;
      justify-content: center;
      cursor: pointer;
    }
    .dumbbell-land-create-plan-card-plus-badg:active{
      opacity: 0.3
    }

  }


}


.dumbbell-land-create-plan-card-header-view{
  width: 100%;
  background-color:#8D89FD;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  .dumbbell-land-create-plan-card-header-item{
    span{
      color: white;
    }

  }
}

.dumbbell-land-create-plan-card-deliver-time-view{
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.dumbbell-land-create-plan-card-deliver-time-view{
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 5px;
  span{
    font-family: YekanRegular;
    direction: rtl;
  }
  border-bottom: 1px solid lightgrey;
}

.dumbbell-land-create-plan-card-desc-input-view{
  font-family: YekanBold;
  text-align: right;
  padding: 0 10px;
}

.trash-class:active{
  opacity: 0.3;
}
.trash-class{
  cursor: pointer;
}
.land-pic:active{
  opacity:0.3
}
.land-pic{
  width: 100%;
  cursor: pointer;
}

.land-upload-btn{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  span:active{
    opacity:0.3
  }
  span{
    font-size:13px;
    cursor: pointer;
    background-color: #2f54eb;
    color: white;
    font-family: YekanMedium;
    padding: 4px 20px;
    border-radius: 4px;
  }
}

.s-land-input{
  border: none;
  border-radius: 5px;
  font-family: YekanBold;
  direction: rtl;
  text-align: right;
  width: 100%;
  padding: 5px 10px;
  outline: none;
}

.land-change-btn{
  display: flex;
  justify-content: center;
  padding: 15px;
  span:active{
    opacity:0.3
  }
  span{
    font-size:13px;
    cursor: pointer;
    background-color: #2f54eb;
    color: white;
    font-family: YekanMedium;
    padding: 4px 20px;
    border-radius: 4px;
  }
}