.dumbbell-trainee-welcome{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
}



.pl-trainer-sec{
  display: flex;
  flex-direction: column;
  align-items: center;
  span{
    font-family: YekanRegular;
    text-align: center;
    display: block;
  }
  h5{
    font-size: 20px;
    text-align: center;
    font-family: YekanBold;
    margin: 0;
  }
}

.pl-trainer-thumb{
  width: 80px;
  height: 80px;
  .welcome-thumb{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    border: 2px solid lightgray;
  }
  .default-thumb{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
  }
}

.pl-trainer-audio-description{
  display: flex;
  justify-content: center;
  .pl-trainer-audio-btn{
    background-color: #8d89fd;
    font-family: YekanMedium;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 12px;
    width: 250px;
    text-align: center;
    img{
      margin: 0 10px;
      width: 20px;
    }
  }
}

.pl-welcome-message{
  font-family: YekanBold;
  direction: rtl;
  text-align: center;
  margin-top: 10px;
  p{
    font-family: 'YekanMedium';
  }
}

.pl-digital-plan-sec{
  margin: 5px;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    font-family: YekanMedium;
    direction: rtl;
  }
  button{
    font-family: YekanMedium;
    color: white;
    background-color: #a7a4fd;
    border:none;
    border-radius: 4px;
    padding: 4px 12px;
    width: 250px;
  }
}

.pl-printable-plan-sec{
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  p{
    font-family: YekanMedium;
    direction: rtl;
  }
  button{
    font-family: YekanMedium;
    color: white;
    background-color: #e0aefd;
    border:none;
    border-radius: 4px;
    padding: 4px 12px;
    width: 250px;
  }
}

.pl-learn-plan-sec{
  margin: 5px;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    font-family: YekanMedium;
    direction: rtl;
  }
  button{
    font-family: YekanMedium;
    color: white;
    background-color: #fd96fa;
    border:none;
    border-radius: 4px;
    padding: 4px 12px;
    width: 250px;
  }
}
.pl-header{
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 16px 12px 16px;
  margin: 5px;
}
