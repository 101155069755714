.ipg-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  .success-pay{
    background-color: mediumseagreen;
    color: white;
    font-family: YekanBold;
    width: 150px;
    padding: 5px;
    border-radius: 4px;
    margin: 10px;
    text-align: center;
  }
  .failed-pay{
    background-color: indianred;
    color: white;
    font-family: YekanBold;
    width: 150px;
    padding: 5px;
    border-radius: 4px;
    margin: 10px;
    text-align: center;
  }

}