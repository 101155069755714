.dumbbell-invoice-page{
  padding: 50px 0;
  .dumbbell-invoice-page-title{

    display: flex;
    justify-content: center;
    span{
      font-family: YekanBold;
      color: white;
      font-size: 25px;
    }
  }


  .dumbbell-invoice-page-form{
    background-color: white;
    border:1px solid lightgrey;
    border-radius: 12px;
    padding: 15px;
    margin: 10px 10%;

    .dumbbell-invoice-page-form-row:last-child{
      border: none;
    }
    .dumbbell-invoice-page-form-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid lightgrey;
      padding: 10px 15px;
      .dumbbell-invoice-page-form-label{
        font-family: YekanRegular;
      }
      .dumbbell-invoice-page-form-value{
        font-family: YekanBold;
        direction: rtl;
      }
    }

  }


  .dumbbell-invoice-page-payable{
    display: flex;
    align-items: center;
    justify-content: space-around;
    .dumbbell-invoice-page-payable-value{
      font-family: YekanBold;
      direction: rtl;
      color: white;
      font-size: 25px;
    }
    .dumbbell-invoice-page-payable-label{
      font-family: YekanRegular;
      direction: rtl;
      color: white;
    }
  }



}



.payed-label{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #4bb771;
  border-radius: 10px;
  border:1px solid lightgrey;
  margin: 10px  10%;
  padding: 10px;
  span{
    font-family: YekanBold;
    color: white;
    font-size: 18px;
    margin: 0 0 0 0;
  }
}