.package-card{
  background-color: #F8F8FF;
  border:1px solid #BCB7B7;
  margin: 10px 20px;
  border-radius: 8px;
  padding: 15px 20px;

  .package-card-header{
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-bottom: 0px solid #D0D0D0 !important;
    height: 30px;
    h6{
      font-family: YekanHeavy;
      font-size: 14px;
    }
    img{
      position: absolute;
      left: 0px;
      top: -5px;
      background-color: #ffcc67;
      padding: 5px;
      border-radius: 4px;
      margin: 0 5px;
    }
  }

  .package-card-des{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 5px;
    .package-card-des-item{
      display: flex;
      align-items: center;
      justify-content:flex-end ;
      margin: 2px 0;
      .package-card-des-item-label{
        font-family: YekanRegular;
        direction: rtl;
      }
      .package-card-des-item-bullet{
        width:15px;
        height:15px;
        border-radius: 20px;
        background-color: #C2B9F2;
        margin: 0 5px;
      }
    }

  }

  .package-card-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    span{
      direction: rtl;
    }
    .package-card-pay_amount{
      font-family: YekanHeavy;
      color: #696868;
      font-size: 20px;
    }
    .package-card-amount{
      font-family: YekanRegular;
      color: #A8A8A8;
      font-size: 13px;
      text-decoration: line-through;
    }
    .package-card-extra_label{
      font-family: YekanRegular;
      font-size: 13px;
      color:#8D89FD
    }
  }
  .package-card-btn{
    display: flex;
    justify-content: center;
    span{
      width: 50vw;
      padding: 8px 10px;
      border-radius: 4px;
      font-family: YekanRegular;
      color: white;
      background-color: #8D89FD;
      text-align: center;
    }
  }

}

.product-image{
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f1f1f1;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  .anticon{
    font-size: 45px;
    color: #e3e3e3;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  .product-image-inactive{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.73);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: YekanBold;
  }
}

.product-names{
  display: flex;
  flex-direction: column;
  align-items: center;
  .product-fa_names{
    h4{
      font-family: YekanBold;
      color: black;
      margin: 16px 0 0;
      font-size: 16px;
      direction: rtl;
    }
  }
  .product-en_names{
    h6{
      font-size: 13px;
      color: gray;
      margin: 0;
    }
  }
}
.dumbbell-add-trainee-form-item{
  .image-btn{
    background-color: #8086ff;
    font-family: YekanRegular;
    text-align: center;
    border: none;
    border-radius: 5px;
    min-width: 160px;
    padding: 8px 4px;
    direction: rtl;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product-add-image{
  display: flex;
  justify-content: center;
  width: 100%;
  img{
    width: 250px;
    border-radius: 8px;
  }
}

.product-description{
  display: flex;
  justify-content: center;
  p{
    font-family: YekanMedium;
    direction: rtl;
    text-align: center;
  }
}

.owner_description{
  display: flex;
  justify-content: center;
  p{
    font-family: YekanRegular;
    color: gray;
    direction: rtl;
    text-align: center;
  }
}
.order-in-regal{
  background-color: #c7c6f1;
  min-width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'YekanBold';
  position: absolute;
  top: -5px;
}

.add-remove-section{
  display: flex;
  border: 1px solid #999999;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  span{
    width: 30px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: YekanBold;
  }
  .add-section-btn{
  }
  .count-section-btn{
    border-left: 1px solid #999999;
    border-right: 1px solid #999999;

  }
  .remove-section-btn{

  }
}


.options-card-sec{
  background-color: #e9e9e9;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'YekanBold';
  position: absolute;
  top: -5px;
  right: 45px;
  padding: 0 6px;
  span{
    font-size: 12px;
  }
}