.plan-add-header{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px 5px;
  .plan-add-header-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0  10px;
    .name{
      font-family: YekanBold;
    }
    .mobile{
      font-family: YekanThin;
      font-size: 13px;
    }
  }
  .plan-add-header-image{
    position: absolute;
    left: 20px;
    top: 15px;
    img{
      width: 80px;
    }
  }
}

.plan-add-header-type{
  background-color: #F5F5F5;
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
  select{
    border: none;
    background-color: transparent;
    margin: 5px 15px 5px 5px;
    outline: none;
    font-family: YekanRegular;
    font-size: 12px;
  }
}

.plan-add-tabs{
  display: flex;
  align-items: center;
  margin-top: 20px;
  .plan-add-tabs-item:active{
    opacity: 0.3;
  }
  .plan-add-tabs-item{
    cursor: pointer;
    flex:1;
    background-color: white;
    margin: 0 5px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    justify-content: center;

    span{
      font-family: YekanMedium;
      color: #434343;
      margin: 0  5px;
    }
  }
}

.plan-sec{
  margin: 10px 5px;
}
.plan-bar-list{
  padding:14px 16px;
  background-color: #f8f8f8;
  border-radius: 5px;
  .plan-bar-item{
    position: relative;
    width:60px;
    background-position: center;
    background-repeat: no-repeat;
    font-family: YekanRegular;
    cursor: pointer;
    color: #afafaf;
  }
  .plan-bar-item:active{
    opacity: 0.3;
  }
  .plan-bar-item-deactive{
    position: relative;
    height:60px;
    width:60px;
    border-radius: 60px;
    background-position: center;
    //background-size: contain;
    background-repeat: no-repeat;
    border:3px solid #dcdbf1;
    filter:saturate(0)
  }
}

.plan-add-fix-footer-wr{
  position: fixed;
  bottom: 0;
  width: 100%;
  .plan-add-fix-footer-sec{
    background-color: white;
    display: flex;
    position: relative;
    padding: 10px 0px;
    height: 50px;
    direction: rtl;
    .plan-add-fix-footer-btns{
      display: flex;
      align-items: flex-start;
      margin: 0 20px;
      span{
        font-family: YekanRegular;
        color: white;
        cursor: pointer;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 0 50px;
      }
      span:active{
        opacity: 0.4;
      }
      .save-btn{
        background-color: #605BFF;
      }
      .save-send-btn{
        background-color: #8D89FD;
      }
    }


    .plan-add-fix-footer-mic{
      position: absolute;
      left: 15px;
      top: -25px;
      background-color: white;
      border-radius: 50px;
      padding: 10px 15px;
      cursor: pointer;
    }
    .plan-add-fix-footer-mic:active{
      opacity: 0.3;
    }

  }
}


.plan-create-list{
  margin: 20px 0px 10px 20px;
  padding-bottom: 100px;
}

.plan-card{
  margin-top: 15px;
  display: flex;
  align-items: center;

  .plan-card-main{
    background-color: white;
    border-radius: 5px;
    flex: 10;
    display: flex;
    .plan-card-text-trainee{
      flex:10;
      textarea{
        resize: none;
        width: 100%;
        direction: rtl;
        font-family: YekanRegular;
        padding: 4px 10px;
        border: none;
        outline: none;
        border-radius: 8px 8px 8px 8px;
      }
    }
    .plan-card-actions{
      flex:2;
      background-color: #F8F8F8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 8px;

      .plan-card-actions-btns{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-around;
      }

      .plan-card-actions-delete:active{
        opacity: 0.3;
      }
      .plan-card-actions-delete{
        cursor: pointer;
      }

      .plan-card-actions-edit:active{
        opacity: 0.3;
      }
      .plan-card-actions-edit{
        cursor: pointer;
      }


      .plan-card-actions-add{
        cursor: pointer;
      }
      .plan-card-actions-add:active{
        opacity: 0.3;
      }
    }
  }

  .plan-card-number-line{
    position: absolute;
    height: 105px;
    width: 5px;
    background-color:#FFCE31;
    top: 26px;
  }
  .plan-card-number{
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: YekanBold;
    border-radius: 30px;
    background-color: #FFCE31;
    margin: 0 10px;
    font-size: 20px;
  }

}



.plan-exercise-row{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 5px;
  span{
    font-family: YekanMedium;
    margin: 0 10px;
  }
  .bullet-blue{
    background-color: #8D89FD;
    padding: 8px;
    border-radius: 5px;
    margin: 0;
  }
}

.guest-header{
  background-color: #2196F3;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .guest-header-hint{
    font-family: YekanRegular;
    color: white;
  }
  .guest-header-btn{
    background-color:white;
    padding: 4px 10px;
    border-radius: 8px;
    font-family: YekanBold;
    margin: 10px 0;
  }

}

@media screen and (min-width: 800px) {
  .plan-add-fix-footer-wr{
    width: 42vw !important;
  }
}


