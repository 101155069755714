.dumbbell-trainee-container{

  .dumbbell-trainee-search-sec{
    display: flex;
    justify-content:center;
    margin: 0 10px;
    .dumbbell-trainee-search{
      display: flex;
      background-color:white;
      border-radius:8px;
      width: 100%;
      .dumbbell-pay-filter{
        background-color: #EFEFEF;
        padding: 15px 13px;
        border-radius: 8px 0 0 8px;
        display: flex;
        select{
          font-family: YekanBold;
          border: none;
          outline: none;
          background-color: #EFEFEF;
          color: #7D7777;
        }
      }
      input{
        width: 100%;
        border: none;
        text-align: right;
        font-family: YekanRegular;
        outline: none;
        font-size: 15px;
        padding: 0 10px;
        direction:rtl;
      }
      img{
        padding: 15px 13px;
      }
    }

  }

  .dumbbell-trainee-total-sec{
    display: flex;
    justify-content: flex-end;
    margin: 4px 25px;
    span{
      font-family: YekanBold;
      color: white;
      direction: rtl;
    }
  }
  .dumbbell-trainee-list-trainee{
    margin: 200px 0 0 0;
    padding-bottom: 80px;
  }

  .dumbbell-pay-card{
    background-color: white;
    margin: 10px 10px;
    border-radius: 8px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 10px 10px;
    .dumbbell-trainee-card-trainee{
      display: flex;
      align-items: center;
      .dumbbell-trainee-card-trainee-info{
        display: flex;
        flex-direction:column;
        align-items:flex-end;
        margin: 0 10px;
        .dumbbell-trainee-card-trainee-info-name{
          font-family: YekanBold;
          direction:rtl;
        }
        .alert-message{
          background-color: #FF8989;
          color: white !important;
          border-radius:4px;
          padding: 2px 10px;
        }
        .dumbbell-trainee-card-trainee-info-mobile{
          font-family: YekanRegular;
          font-size: 13px;
          color: gray;
        }
      }
    }

    .dumbbell-pay-card-message{
      margin: 0 0 0 15px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .dumbbell-pay-value{
        font-family:YekanBold;
        font-size:15px;
        direction: rtl;
      }

      .dumbbell-pay-unit{
        font-family:YekanRegular;
        direction: rtl;
      }

    }

  }

  .fix-top-sec{
    position: fixed;
    z-index: 2;
    background-color: #8a86fe;
    width: 96%;
    top: 0;
    .fix-content{
      padding: 0;
    }
  }

}

.dumbbell-trainee-card-trainee-info-row{
  display: flex;
  .key{
    color: #A1A1A1;
    font-family: YekanRegular;
    margin: 0 0 0 15px;
    width: 75px;
    text-align: right;
    direction: rtl;
    font-size: 12px;
  }
  .val{
    color: #393939;
    font-family: YekanBold;
    width: 120px;
    text-align: right;
    font-size: 13px;

  }

}

@media screen and (min-width: 800px) {
  .fix-top-sec{
    width: 41vw !important;
  }
}


.dumbbell-pay-total-sec{
  background-color: #FFCE31;
  margin: 10px;
  border-radius:8px;
  display:flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  .dumbbell-pay-total-price{
    direction: rtl;
    font-family: YekanBold;
    font-size: 18px;
  }
  .dumbbell-pay-total-count{
    direction: rtl;
    font-family: YekanMedium;
  }
}

.dumbbell-booking-mg{
}

.dumbbell-booking-mg-tabs{

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 10px 10px ;
  border-bottom: 1px solid lightgray;
  margin: 5px 10px;
  .active{
    background-color:#8D89FD;
    color: white;
    border-radius:5px;
  }
  .dumbbell-booking-mg-tabs-item:active{
    opacity:0.3
  }
  .dumbbell-booking-mg-tabs-item{
    cursor: pointer;
    span{
      font-family: YekanBold;
      padding: 15px;
    }
  }

}

.dumbbell-booking-mg-date-filter{
  display: flex;
  margin: 0 4px;
  //background-color: red;
  .dumbbell-booking-mg-date-filter-item{
    flex:1;
    padding: 2px 5px;
    select{
    width: 100%;
      background-color:#8b88dd;
      color: white;
      text-align: center;
      border-radius: 5px;
      outline: none;
      font-size:14px;
      border: none;
      padding: 8px;
      font-family: YekanBold;
  }
  }
}


.dumbbell-booking-mg-service{
  padding: 2px;
  display: flex;
  select{
    margin: 0 7px;
    width: 100%;
    background-color: #8b88dd;
    color: white;
    text-align: center;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    border: none;
    padding: 8px;
    font-family: YekanBold;
    option{
      direction:rtl
    }
  }
}


.dumbbell-booking-mg-booking-list{


  .dumbbell-booking-mg-booking-item{
    border: 1px solid #B6B6B6;
    margin: 10px;
    border-radius: 10px;
    background-color:#E2E1FF;
    padding: 10px 10px;
    .booking-turn-pending-status{
      background-color: transparent;
      border: 1px solid #fdbd76;
      color: #f69f41;
    }
    .booking-turn-booked-status{
      background-color: transparent;
      border: 1px solid #8D89FD;
      color: #8D89FD;
    }
    .booking-turn-cancel-me-status{
      background-color: transparent;
      border: 1px solid #FA8298;
      color: #d92f4d;
    }
    .booking-turn-cancel-client-status{
      background-color: transparent;
      border: 1px solid #FA8298;
      color: #d92f4d;
    }
    .booking-turn-accepted-status{
      background-color: transparent;
      border: 1px solid #B0B0BD;
      color: #B0B0BD;
    }
    .booking-turn-not-come-status{
      background-color: transparent;
      border: 1px solid #FF9031;
      color: #FF9031;
    }
    .dumbbell-booking-mg-booking-item-header{
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      border-radius: 4px;
      font-family: YekanBold;
      font-size: 12px;
      .dumbbell-booking-mg-booking-item-header-reject:active{
        opacity:0.3
      }
      .dumbbell-booking-mg-booking-item-header-reject{
        cursor: pointer;
      background-color: #8f8f8f;
        font-family: YekanRegular;
        color: white;
        width: 100px;
        text-align: center;
        margin: 3px;
        border-radius: 4px;
      }
      .dumbbell-booking-mg-booking-item-header-approve:active{
        opacity: 0.3;
      }
      .dumbbell-booking-mg-booking-item-header-approve{
        cursor: pointer;
        background-color: #00BCD4;
        font-family: YekanRegular;
        color: white;
        width: 100px;
        text-align: center;
        margin: 3px;
        border-radius: 4px;
      }
    }

    .dumbbell-booking-mg-booking-item-body{

      .dumbbell-booking-mg-booking-item-body-info-row{
        display: flex;
        justify-content: space-between;
        margin: 10px 10px;
        .mobile-val{
          font-family: YekanBold;
        }
        .name-val{
          font-family: YekanBold;
        }
      }


      .dumbbell-booking-mg-booking-item-body-time-row{
        display:flex;
        justify-content: center;
        align-items: center;
        span{
          font-family: YekanBold;
          font-size:25px;
          margin: 0 4px;
        }
      }

      .dumbbell-booking-mg-booking-item-body-desc-row{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 10px 10px;
        span{
          font-family: YekanMedium;
          direction: rtl;
          color: gray;
        }
        p{
          font-family: YekanRegular;
          direction: rtl;
        }
      }


    }

  }

}


.dumbbell-booking-mg-calender-list{
  margin: 10px 20px;
  .dumbbell-booking-mg-calender-item{
    margin: 15px 0;
    display: flex;
    .dumbbell-booking-mg-calender-item-capacity:active{
      opacity:0.3
    }


    .dumbbell-booking-mg-calender-item-capacity-empty{
      cursor: pointer;
      background-color: #BBB9FF;
      color: white;
      font-size: 20px;
      padding: 15px;
      border-radius: 0 10px 10px 0;
    }

    .dumbbell-booking-mg-calender-item-capacity-limit{
      cursor: pointer;
      background-color: #FFC593;
      color: white;
      font-size: 20px;
      padding: 15px;
      border-radius: 0 10px 10px 0;
    }

    .dumbbell-booking-mg-calender-item-capacity-fill{
      cursor: pointer;
      background-color: #FC9CAE;
      color: white;
      font-size: 20px;
      padding: 15px;
      border-radius: 0 10px 10px 0;
    }
    .dumbbell-booking-mg-calender-item-time:active{
      opacity:0.3
    }
    .active-time{
      background-color:#D3D3FF  !important;
    }
    .dumbbell-booking-mg-calender-item-time{
      cursor: pointer;
      flex:10;
      background-color: #F3F3F3;
      border-radius: 10px 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-family: YekanBold;
        font-size: 20px;
      }
    }

  }

}

.turn-label{
  display: flex;
  span{
    margin: 0 10px;
  }
}

.service-item{
  border:2px solid lightgray;
  border-radius:10px;
  margin: 15px 20px;

  .service-item-header{
    background-color:#8D89FD;
    border-radius:8px 8px 0 0;
    padding:8px;
    display: flex;
    justify-content: space-between;

    .service-item-header-edit{
      background-color:white;
      font-family:YekanRegular;
      padding:2px 15px;
      border-radius:4px;
      font-size:12px;
      margin-left:5px;
    }

    .service-item-header-status{
      span{
        margin: 0 5px;
        font-family: YekanRegular;
        color: white;
      }
    }
  }

  .service-item-row:last-child{
    border-bottom: none;
  }
  .service-item-row{
    display:flex;
    align-items:center;
    justify-content: flex-end;
    margin: 2px 20px;
    padding:5px 0;
    border-bottom: 1px solid #e8e8e8;
    .service-item-row-label{
      font-family:YekanBold;
      margin: 0 10px;
      direction:rtl;
    }

    .service-item-row-value{
      direction: rtl;
      font-family:YekanRegular;

    }
  }
}

.service-add-btn{
  position: fixed;
  bottom:30px;
  right:30px;
  color: white;
  font-family:YekanRegular;
  background-color: #B667E5;
  padding:8px 10px;
  border-radius:5px
}



  .add-service-drawer-item{
    display: flex;
    justify-content: space-between;
    margin: 15px 10px;
    align-items: center;
    input{
      border-radius:4px;
      border:1px solid lightgrey;
      background-color: #eeeeee;
      font-family: YekanMedium;
      width: 200px;
      text-align: center;
      padding: 10px 0;
      font-size: 15px;
    }
    textarea{
      border-radius:4px;
      border:1px solid lightgrey;
      background-color: #eeeeee;
      font-family: YekanMedium;
      width: 200px;
      text-align: center;
      padding: 10px 0;
      font-size: 15px;
    }
    .add-service-drawer-item-label{
      width: 150px;
      text-align:right;
      font-family: YekanBold !important;
    }
  }

  .add-service-drawer-btn{
    display: flex;
    justify-content: center;
    margin-top:40px;
    span{
      background-color: #B667E5;
      color: white;
      font-family:YekanMedium;
      padding:8px 10px;
      width: 200px;
      border-radius: 5px;
      text-align: center;
    }
  }

.add-service-drawer-self-btn{
  display: flex;
  justify-content: center;
  margin-top:10px;
  span{
    background-color: #B667E5;
    color: white;
    font-family:YekanMedium;
    padding:3px 10px;
    width: 200px;
    border-radius: 5px;
    text-align: center;
  }
}



.dumbbell-booking-mg-booking-item-header-status{
  select{
    width: 150px;
    font-family: YekanBold;
    text-align: center;
    border: none;
    border-radius: 5px;
  }
}

.service-not-exist{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 150px;
  .service-not-exist-text{
    font-family: YekanBold;
  }

  .service-not-exist-btn{
    background-color: #8D89FD;
    color: white;
    font-family:YekanMedium;
    padding:3px 10px;
    width: 200px;
    border-radius: 5px;
    text-align: center;
  }
}

.add-service-drawer-header{
  background-color: #8D89FD;
  color: white;
  font-family: YekanBold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

}

.service-list{
  padding-bottom: 50px;
}

.member-list-header{
  font-family: YekanBold;
  color: white;
  background-color: #8D89FD;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.member-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #e3e3e3;
  border-radius: 8px;
  margin: 10px;
  .member-item-delete{

  }
  .member-item-user{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .member-item-user-info{
      font-family: YekanBold;
    }
    .member-item-user-mobile{
      font-family: YekanBold;
    }
  }
}



.booking-card-price-sec{
  display:flex;
  flex-direction: column;
  margin: 10px 40px;
  .booking-card-price-row:last-child{
    border:none;
  }
  .booking-card-price-row{
    display: flex;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    justify-content: space-between;
    .booking-card-price-item{
      h6{
        font-family: YekanHeavy;
        font-size: 14px;
        direction: rtl;
      }
      span{
        font-family: YekanRegular;
        direction: rtl;
        color: #5A5A5A
      }
    }

  }

}

.booking-card-price-btn{
  display: flex;
  justify-content: center;
  span{
    font-family: YekanBold;
    color: white;
    background-color: #8D89FD;
    border-radius: 5px;
    padding: 4px 20px;
    min-width: 200px;
    text-align: center;
  }
}

.booking-card-description-sec{
  margin: 20px 25px 15px 25px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  span{
    font-family: YekanBold;
    font-size: 12px;
  }
  p{
    direction: rtl;
    font-family: YekanRegular;
  }
}

.location-btn{
  font-size: 12px;
  margin: 10px 0 !important;
  border-radius: 4px !important;
  .page-actions-btn-label-left{
    border-radius: 0 !important;
    padding: 12px;
  }
}
