.dumbbell-header-container{
  display: flex;
  justify-content: space-between;

  .dumbbell-header-logo{
    padding: 5px 0 0 15px;
  }
  .dumbbell-header-user:active{
    opacity:0.3
  }
  .dumbbell-header-user{
    cursor: pointer;
    display: flex;
    padding: 15px 20px;
    .dumbbell-header-info{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 10px;
      span{
        font-family: YekanRegular;
        color:white;
      }
    }

  }

}
