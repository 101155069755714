.my-step-card{
  background-color: #8D89FD;
  margin: 10px 30px;
  border-radius: 16px;
  padding: 15px;
  min-height: 180px;
  position: relative;
}

.my-step-card-cheap{
  display: flex;
  justify-content: flex-start;
  img{
    width: 50px;
  }
}

.my-step-card-credit{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  span{
    position: absolute;
    bottom: 6px;
    font-family: YekanThin;
  }
  h6{
    font-family: YekanBold;
    font-size: 35px;
    color: white;
  }
}

.my-step-card-info{
  display: flex;
  justify-content: space-between;
  font-family: YekanMedium;
  color: white;
  position: absolute;
  bottom: 5px;
  width: 91%;
}

.my-step-reward{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 25px;

}
.unit-reward{
  position: absolute;
  bottom: 0px;
}
.gold-reward{
  background-color: #E1BD49;
}

.silver-reward{
  background-color: #AFAFAF;
}

.pink-reward{
  background-color: #FF97DA;
}

.my-step-debt{
  margin: 0 30px;
  background-color: #ededed;
  border: 2px solid #d3d1f5;
  border-radius: 12px;
  padding: 14px;
}

.my-step-debt-amount{
  display: flex;
  align-items: center;
  justify-content: center;
  h6{
    font-family: YekanBold;
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  span{
    font-family: YekanRegular;
    margin: 0 10px;
  }
}

.my-step-debt-checkout-btn{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  span{
    background-color: #8D89FD;
    color: white;
    font-family: YekanRegular;
    text-align: center;
    padding: 4px;
    width: 100px;
    border-radius: 8px;
  }
}

.my-step-debt-checkout-description{
  display: flex;
  justify-content: center;
  p{
    font-family: YekanRegular;
    text-align: center;
    color: #514D4D;
    direction: rtl;
    font-size: 13px;
    margin: 10px 15px;
  }
}
.my-step-learn{
  display: flex;
  justify-content: center;
  margin: 10px 30px;
  background-color: #8D89FD;
  color: white;
  font-family: YekanRegular;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
}

.my-step-support{
  display: flex;
  justify-content: center;
  margin: 10px 30px;
  background-color: #635dff;
  color: white;
  font-family: YekanRegular;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
}

.my-step-logout{
  display: flex;
  justify-content: center;
  margin: 10px 30px;
  background-color: #ff5d5d;
  color: white;
  font-family: YekanRegular;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
}

.my-step-title{
  display: flex;
  justify-content: space-between;
  font-family: YekanBold;
  margin: 15px 40px 5px 40px;
}

.report-filter{
  display: flex;
  .report-filter-item{
    margin: 0 10px;
  }
}

.reward-buy-drawer{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  .reward-buy-drawer-message{
    font-family: YekanBold;
  }
  .reward-buy-drawer-btns{
    display: flex;
    margin: 20px 0 0 0;
    span{
      font-family: YekanMedium;
      padding: 4px 10px;
      border-radius: 8px;
      margin: 0 10px;
      width: 100px;
      text-align: center;
    }
    .accept{
      background-color: #99aaff;
      color: white;
    }
    .deny{
      background-color: #c9c9c9;
      color: #7b7b7b;
    }
  }
}
.my-step-reward-item{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: YekanRegular;
  padding: 8px 4px 14px 4px;
  border-radius: 4px;
  margin: 0 10px;
  position: relative;
}
.width-100{
  width: 100px;
}
.quick-access{
  display: flex;
  justify-content: space-between;
  margin:20px 30px 10px;
  align-items: baseline;
  .quick-access-item{
    flex:1;
    //border: 1px solid lightgray;
    display: flex;
    flex-direction:column;
    align-items: center;
    position: relative;
    max-width: 100px;
    img{
      width: 40px;
    }
    h6{
      font-family: YekanBold;
      font-size: 13px;
    }
    span{
      position: absolute;
      top: -14px;
      left:16%;
      background-color: #f86d6d;
      color: white;
      font-family: 'YekanRegular';
      width: 30px;
      height: 30px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}

.my-step-page-report-chart{
  background-color: #ffffff;
  margin: 10px 30px;
  border: 2px solid #f3f3f3;
  border-radius: 12px;
}

.my-step-page-report-statistic{
  display: flex;
  justify-content: space-between;
  margin: 10px 30px;
  background-color:#f1f1f1;
  border-radius: 8px;
  padding: 10px 8px 4px ;
  border:2px solid #dce1ff;
  .my-step-page-report-statistic-item{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h6{
      font-size: 13px;
      font-family: YekanRegular;
    }
    span{
      font-size: 16px;
      font-family: YekanBold;
    }
  }
}


.my-step-debt-row{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px;
  justify-content: space-between;
  margin: 12px 12px;
  .my-step-debt-btn{
    border-radius: 4px;
    padding: 4px 12px;
    font-family: 'YekanBold';
    font-size: 13px;
    width: 90px;
    text-align: center;
  }
  .force-debt-btn{
    background-color: #e9b5b4;
  }
  .debt-btn{
    background-color: #94a7f6;
  }
  .my-step-debt-amount{

  }
}


.detail-pay{
  display: flex;
  justify-content: center;
  span{
    font-family: YekanMedium;
    background-color: orange;
    color: white;
    width: 200px;
    border-radius: 8px;
    padding: 4px 8px;
    text-align: center;
  }
}


.detail-pay-body{

}
.detail-pay-header{
  display: flex;
  justify-content: center;
  padding: 8px;
  border-bottom: 1px solid lightgrey;
  margin: 0 24px;
  h5{
    font-size: 14px;
    font-family: YekanMedium;
    padding: 0;
    margin: 0;
  }
}

.detail-pay-list{
  margin: 0 44px;
  .detail-pay-item{
    background-color: #E2E1FF;
    margin: 10px 10px;
    border: 1px solid #B6B6B6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    flex-direction: column;
    .detail-pay-item-row:last-child{
      border-bottom: none !important;
    }
    .detail-pay-item-row{
      border-bottom: 1px solid #cbc9c9;
      display: flex;
      align-items: center;
      justify-content: center;
      direction: rtl;
      padding: 4px 0;
      .detail-pay-item-row-info{
        display: flex;
        align-items: center;
        width: 120px;
        span{
          margin: 0 2px;
        }
      }
      label{
        display: block;
        color: #A1A1A1;
        font-family: YekanRegular;
        margin: 0 0 0 15px;
        width: 75px;
        text-align: right;
        direction: rtl;
        font-size: 12px;
      }
      span{
        display: block;
        color: #393939;
        font-family: YekanBold;
        text-align: right;
        font-size: 13px;
      }
    }

  }

}