.dumbbell-add-trainee-form{

  margin: 15px 10px;
  .dumbbell-add-trainee-form-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .select-wr{
      background-color: #EBEBEB;
      padding: 8px 5px;
      border-radius: 5px;
      min-width: 160px;
    }
    select{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      min-width: 160px;
      text-align: center;
      border: none;
    }
    input{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      text-align: center;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 4px;
      direction: rtl;
    }
    textarea{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      text-align: right;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 8px;
      direction: rtl;
    }
    span{
      text-align: right;
      font-family: YekanMedium;
    }
  }

  .dumbbell-add-trainee-form-submit{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    span{
      background-color: #605BFF;
      color: white;
      font-family: YekanRegular;
      width: 200px;
      border-radius: 5px;
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
    }
    span:active{
      opacity: 0.3;
    }

  }

}

.dumbbell-add-trainee-form-submit-send-invoice{
  display: flex;
  justify-content: center;
  margin: 40px 0;
  span{
    background-color: #605BFF;
    color: white;
    font-family: YekanRegular;
    width: 200px;
    border-radius: 5px;
    padding: 8px 10px;
    text-align: center;
    cursor: pointer;
  }
  span:active{
    opacity: 0.3;
  }

}

.dumbbell-add-trainee-form-submit-pay-my-invoice{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  span{
    background-color: #893cdc;
    color: white;
    font-family: YekanRegular;
    width: 200px;
    border-radius: 5px;
    padding: 4px 10px;
    text-align: center;
    cursor: pointer;
  }
  span:active{
    opacity: 0.3;
  }

}
.dumbbell-add-trainee-form-submit-send-invoice{
  display: flex;
  justify-content: center;
  margin: 10px 0;
  span{
    background-color: #605BFF;
    color: white;
    font-family: YekanRegular;
    width: 200px;
    border-radius: 5px;
    padding: 8px 10px;
    text-align: center;
    cursor: pointer;
  }
  span:active{
    opacity: 0.3;
  }

}

.total-section{
  margin:30px 0 ;
  display: flex;
  flex-direction: column;
  align-items: center;
  .total-section-label{
    font-family: YekanMedium;
    color: #2f54eb;
  }
  .total-section-price{
    font-family: YekanBold;
    color: #4761cb;
    font-size: 25px;
    direction: rtl;
  }
}

.s-input{
  background-color: #EBEBEB;
  font-family: YekanRegular;
  text-align: center;
  border: none;
  border-radius: 5px;
  width: auto !important;
  padding: 8px 4px;
  direction: rtl;
}

.add-package-img:active{
  opacity: 0.3;
}
.add-package-img{
  cursor: pointer;
}

.package-descriptions-row{
  direction: rtl;
  margin: 5px 4px;
  font-family: 'YekanRegular';
  display: flex;
  align-items: center;
  .package-bullet{
    background-color: #ffcc67;
    padding: 5px;
    border-radius: 4px;
    margin: 0 5px;
  }
}

.option-select{
  background-color: #EBEBEB;
  font-family: YekanRegular;
  text-align: center;
  border: none;
  border-radius: 5px;
  min-width: 160px;
  padding: 8px 4px;
  direction: rtl;
  width: 134px !important;
}