@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import "./colors";

@font-face {
    font-family: 'YekanHair';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanThin';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanLight';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanRegular';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanMedium';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanBold';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanHeavy';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanFat';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.ttf')  format('truetype');
}



.master-sec{
    display: flex;
    flex-direction: row;
    .desk-sec{
        flex: 8 1;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            margin-bottom:15px;
            width:120px
        }
        span{
            font-family: "YekanRegular", serif;
            font-size: 18px;
            margin: 5px 0 ;
            color: white;
        }
    }
    .mobile-sec{
        position: relative;
        flex: 8;
        //min-width: 320px;
        max-width: 800px;
        width: 450px;
        //background-color:red;
        //height: 100vh;
        overflow: scroll;
    }
}

@media screen and (max-width: 800px) {
    .desk-sec{
        display: none !important;
    }
}


//utils
.star-pan{
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin:20px
}

.wallet-buy-card{
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    margin: 8px;
    border-radius: 8px;
    padding: 8px 12px;
    .wallet-buy-card-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wallet-buy-card-info-count{
            display: flex;
            align-items: center;
            background-color: white;
            padding: 2px 14px;
            border-radius: 5px;
            img{
                width:20px
            }
        }
        .wallet-buy-card-info-actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            .wallet-buy-card-info-actions-label{
                display: flex;
                align-items: center;
                .wallet-buy-card-info-actions-label-val{
                    color: #686868;
                    margin: 0 5px 4px 5px;
                }
                .wallet-buy-card-info-actions-label-title{
                    font-family: YekanRegular;
                    color: #999999;
                    font-size: 13px;
                }

            }
            .wallet-buy-card-info-actions-btns{
                display: flex;
                align-items: center;
                margin: 4px 0;
                img{
                    width: 25px;
                    margin: 0 8px;
                }
            }
        }
    }

    .ant-progress-bg {
        height: 3px !important;
    }
}


.report-card-info-actions{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .wallet-buy-card-info-actions-label{
        display: flex;
        align-items: center;
        .wallet-buy-card-info-actions-label-val{
            color: #686868;
            margin: 0 5px 4px 5px;
        }
        .wallet-buy-card-info-actions-label-title{
            font-family: YekanRegular;
            color: #999999;
            font-size: 13px;
        }

    }
    .wallet-buy-card-info-actions-btns{
        display: flex;
        align-items: center;
        margin: 4px 0;
        img{
            width: 25px;
            margin: 0 8px;
        }
    }
}

.report-card-date{
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    color: #c6c6c6;
}


.report-item-info-val-level2{
    color: #b5b3b3;
    font-family: YekanRegular;
    font-size: 13px;
}

.ant-notification-notice-message{
    direction: rtl;
    font-family: YekanBold;
}

.float-add-btn{
    background-color: #6277f8;
    color: white;
    position: fixed;
    bottom: 40px;
    right: 30px;
    padding: 8px 17px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'YekanRegular';
    cursor: pointer;
    span{
        margin: 0 10px;
    }
}
.float-add-btn:active{
    opacity: 0.3;
}
.align-center{
    align-items:center;
}

.clickable:active{
    opacity: 0.3
}
.clickable{
    cursor: pointer
}
.bottom-drawer-handle{
    display: flex;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        height: 3px;
        width: 40px;
        background-color: white;
    }
}
.bottom-drawer-body{
    display: flex;
    flex-direction: column;
    align-items:center;
    .bottom-drawer-item{
        display: flex;
        background-color:white;
        align-items: center;
        width: 250px;
        padding: 8px;
        border-radius: 8px;
        margin: 10px 0;
        .badge-count{
            background-color: #F86D6D;
            color: white;
            padding: 4px 4px;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: YekanBold;
        }
        .label{
            flex:10;
            font-family: YekanRegular;
            margin: 0 10px;
            color: #6D6D6D;
            text-align: center;
        }
        img{
            margin: 0 0 0 10px;
        }
    }
}

//tabs

.tabs-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px 4px;
    .active-tab{
        background-color: #8D89FD;
        color: white;
    }


    //.tabs-item:nth-child(odd){
    //    margin-right:10px;
    //}
    //.tabs-item:nth-child(even){
    //    margin-left:10px;
    //}

    .tabs-item{
        margin: 5px;
        flex: 1;
        padding: 8px 10px;
        border-radius: 5px;
        border:1px solid #DBDBDB;
        text-align: center;
        span{
            font-family: YekanRegular;
            font-size: 13px;
        }
    }
}

//search box

.search-row{
    background-color: #F6F6F6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 10px;
    img{
        flex:1
    }
    input{
        flex:10;
        font-family: YekanRegular;
        text-align: center;
        border: none;
        outline: none;
        background-color: transparent;
    }
}

//filter row
.filter-row{
    display: flex;
    justify-content: flex-end;
    margin: 0 10px;
    .filter-item{
        span{
            font-family: YekanBold;
            margin: 0 0 0 5px;
        }
    }

}

.page-data-list{
    margin: 0px 20px;
}



.plan-card-progress{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    .plan-card-progress-station{
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #ffffff;
        .plan-card-progress-station-date{
            position: absolute;
            top: -22px;
            left:-10px;
            width: 50px;
            font-family: YekanBold;
            direction: rtl;
            display: flex;
            align-items: center;
            span{
                margin: 0 2px;
            }
        }
        .plan-card-progress-station-label{
            position: absolute;
            bottom: -25px;
            width: 50px;
            font-family: YekanRegular;
            color: #9F9F9F;
        }

    }
    .plan-card-progress-line{
        width: 100px;
        height: 5px;
        background-color: white;
    }
}


.plan-card-v2-main{
    background-color: #f9f9f9;
    border: 1px solid #B6B6B6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 10px 15px;
}

.plan-card-fix-row{
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
}
.plan-card-fix-trainee{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .trainee-name{
        font-family: YekanBold;
        font-size: 14px;
    }
    .trainee-mobile{
        font-family: YekanRegular;
    }
    .trainee-date{
        font-family: YekanRegular;
        font-size: 12px;
    }
}
.plan-card-fix-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 8px;
    .price{
        font-family: YekanHeavy;
        font-weight: 700;
        font-size: 22px;
    }
    .unit{
        font-family: YekanRegular;
        font-size: 12px;
        position: absolute;
        top: 25px;
    }
}
.plan-card-btn{
    display: flex;
    justify-content: center;
    span{
        font-family: YekanBold;
        color: white;
        background-color: #8D89FD;
        border-radius: 5px;
        padding:4px 20px;
        min-width: 200px;
        text-align: center;
    }
}

.plan-card-target-v2{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 10px;
    .plan-card-target-title{
        font-family: YekanBold;
        font-size: 13px;
    }
    .plan-card-target-desc{
        font-family: YekanRegular;
        font-size: 13px;
    }

}
.plan-card-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid lightgrey;
    margin-top: 10px;
    padding-top: 10px;
}
.actions-icons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .plan-card-actions-item{
        margin: 0 5px;
        img{
            width: 20px;
        }
    }
}

.empty-state{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    img{
        width: 50px;
    }
    span{
        font-family: YekanThin;
        direction: rtl;
    }
}

.loading-state{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    img{
        width:30px
    }
}
.actions-contact{
    display: flex;
    align-items: center;
    .profile-info{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 10px;
        .mobile-name{
            font-size: 12px;
            font-family: YekanBold;
        }
        .mobile-contact{
            font-family: YekanRegular;
        }
    }
    .profile-image{
        width: 50px;
        height:50px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100px;
            border: 2px solid white;
        }
    }

}


.defaultUser{
    background-color: white;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width:32px !important;
    }
}


.dumbbell-trainee-card-message:active{
    opacity: 0.3;
}
.dumbbell-trainee-card-message{
    margin: 0 0 0 15px;
    position: relative;
    cursor: pointer;
    .dumbbell-trainee-card-message-badge{
        position: absolute;
        background-color: #FF8989;
        color: white;
        padding: 3px 8px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        left: 14px;
        font-size: 12px;
    }
}

.page-wrapper{
    margin: 0 30px;
}
.margin-right{
    margin-right: 10px;
}



.loader {
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10000;
}
.padding-bottom{
    padding-bottom: 100px;
}

.menu-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}




//products

.product-swiper-product-card-wrapper {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right:26px;
}

.product-swiper-product-card{
    background-color:lightgray;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .product-swiper-product-card-image{
        width: 100px;
        height: 100px;
        margin: 10px;
        border-radius: 8px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
    }
    .product-swiper-product-card-price{
        height: 15px;
        text-align: center;
        span{
            font-family: YekanBold;
            font-size: 15px;
        }
    }
    .product-swiper-product-card-discount{
        height: 20px;
        text-align: center;
        span{
            font-family: YekanBold;
            font-size: 12px;
            text-decoration: line-through;
            color: red;
        }
    }

    .product-swiper-product-card-name{
        font-family: YekanBold;
        font-size: 13px;
        padding: 5px 0 8px;
        color: gray;
        text-align: center;
        height: 50px;
        margin: 0 5px;
        direction: rtl;
    }

    .product-swiper-product-card-add-btn{
        padding-bottom: 15px;
        img{
            width:25px
        }
    }

}

.image-swiper-placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    .anticon{
        font-size:30px;
        color: gray;
    }
}

.desk-sec{
    .anticon{
        font-size: 62px !important;
        color: lightgray !important;
    }
    .desk-sec-message{
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
            font-size: 16px !important;
            font-family: YekanMedium !important;
            color: lightgray;
        }
    }

}

//.ant-drawer{
//    direction: rtl !important;
//}
//.ant-drawer-content-wrapper{
//
//}

.select-amend{
    width: 120px !important;
    padding: 8px;
    border-radius: 6px;
}

.ant-message-custom-content{
    font-family: YekanRegular;
}

.margin-16{
    margin: 0 16px;
}

@page {
    size: portrait;
    -webkit-size: portrait; /* برای Safari */
}

@media print {
    .desk-sec {
        display: none !important;
    }
}

.meal-desc{
    h5{
        font-family: YekanBold;
    }
    span{
        font-family: YekanRegular
    }
}

.top-shower{
    padding: 12px;
    img{
        width: 100%;
    }
}

.package-card-header{
    direction: rtl !important;
    justify-content:flex-start !important;
}

.swiper-page-beaner{
    margin: 0 24px;
    img{
        width: 100%;
    }
}


.callback-table{
    //border: 1px solid lightgrey;
    margin: 0 10%;
    .callback-table-row{
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        color: white;
        padding: 8px 0;
        label{
            flex:1;
            text-align: center;
            font-family: YekanRegular;
            direction: rtl;
        }
        span{
            flex:1;
            text-align: center;
            font-family: YekanBold;
            direction: rtl;
        }
    }
}

.callback-track-link{
    margin: 19px 10%;
    text-align: center;
    font-family: 'YekanRegular';
    color: white;
    button{
        background-color: mediumseagreen;
        border: none;
        padding: 6px 10px;
        border-radius: 8px;
        margin: 8px 0;
    }
}

.callback-return{
    background-color: #6e77ff;
    border: none;
    padding: 6px 10px;
    margin: 8px 0;
    text-align: center;
    font-family: 'YekanBold';
    color: #ffffff;
}