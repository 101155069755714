.dumbbell-trainee-container{

  .dumbbell-trainee-search-sec{
    display: flex;
    justify-content:center;
    margin: 0 10px;
    .dumbbell-trainee-search{
      display: flex;
      background-color:white;
      border-radius:8px;
      width: 100%;
      .dumbbell-pay-filter{
        background-color: #EFEFEF;
        padding: 15px 13px;
        border-radius: 8px 0 0 8px;
        display: flex;
        select{
          font-family: YekanBold;
          border: none;
          outline: none;
          background-color: #EFEFEF;
          color: #7D7777;
        }
      }
      input{
        width: 100%;
        border: none;
        text-align: right;
        font-family: YekanRegular;
        outline: none;
        font-size: 15px;
        padding: 0 10px;
        direction:rtl;
      }
      img{
        padding: 15px 13px;
      }
    }

  }

  .dumbbell-trainee-total-sec{
    display: flex;
    justify-content: flex-end;
    margin: 4px 25px;
    span{
      font-family: YekanBold;
      color: white;
      direction: rtl;
    }
  }
  .dumbbell-trainee-list-trainee{
    margin: 200px 0 0 0;
    padding-bottom: 80px;
  }

  .dumbbell-pay-card-trainer{
    background-color: #E2E1FF;
    margin: 10px 10px;
    border: 1px solid #B6B6B6;
    border-radius: 10px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 10px 10px;
    .dumbbell-trainee-card-trainee{
      display: flex;
      align-items: center;
      .dumbbell-trainee-card-trainee-info{
        display: flex;
        flex-direction:column;
        align-items:flex-end;
        margin: 0 10px;
        .dumbbell-trainee-card-trainee-info-name{
          font-family: YekanBold;
          direction:rtl;
        }
        .alert-message{
          background-color: #FF8989;
          color: white !important;
          border-radius:4px;
          padding: 2px 10px;
        }
        .dumbbell-trainee-card-trainee-info-mobile{
          font-family: YekanRegular;
          font-size: 13px;
          color: gray;
        }
      }
    }

    .dumbbell-pay-card-message{
      margin: 0 0 0 15px;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .dumbbell-pay-value-trainer{
        font-family: YekanHeavy;
        font-weight: 700;
        font-size: 22px;
        direction: rtl;
      }

      .dumbbell-pay-unit{
        font-family:YekanRegular;
        direction: rtl;
        position: absolute;
        top: 20px;
      }

    }

  }

  .fix-top-sec{
    position: fixed;
    z-index: 2;
    background-color: #8a86fe;
    width: 96%;
    top: 0;
    .fix-content{
      padding: 0;
    }
  }

}

.dumbbell-trainee-card-trainee-info-row{
  display: flex;
  .key{
    color: #A1A1A1;
    font-family: YekanRegular;
    margin: 0 0 0 15px;
    width: 75px;
    text-align: right;
    direction: rtl;
    font-size: 12px;
  }
  .val{
    color: #393939;
    font-family: YekanBold;
    width: 120px;
    text-align: right;
    font-size: 13px;

  }

}

@media screen and (min-width: 800px) {
  .fix-top-sec{
    width: 41vw !important;
  }
}


.dumbbell-pay-total-sec{
  background-color: #FFCE31;
  margin: 10px;
  border-radius:8px;
  display:flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  .dumbbell-pay-total-price{
    direction: rtl;
    font-family: YekanBold;
    font-size: 18px;
  }
  .dumbbell-pay-total-count{
    direction: rtl;
    font-family: YekanMedium;
  }
}

.trx-input{
  background-color: #b3b3f5;
  font-family: 'YekanHeavy';
  width: 72px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #8d8cb7;
  font-size: 12px;
  margin-top: 8px;
}

.trx-output{
  background-color: #ffe2a8;
  font-family: 'YekanHeavy';
  width: 72px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #faad14;
  font-size: 12px;
  margin-top: 8px;
}
.dashed{
  border-left:2px dashed lightgrey;
}


@media screen and (max-width: 456px) {
  .dashed{
    border-top:2px dashed lightgrey;
    border-left: none;
    margin-top: 10px;
    padding-top: 10px;
  }
  .dumbbell-trainee-container .dumbbell-pay-card-trainer{
    flex-direction: column;
  }
  .dumbbell-trainee-container .dumbbell-pay-card-trainer .dumbbell-pay-card-message{
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-evenly;
  }
  .dumbbell-trainee-container .dumbbell-pay-card-trainer .dumbbell-pay-card-message .dumbbell-pay-unit{
    font-family: YekanRegular;
    direction: rtl;
    position: absolute;
    top: 22px;
    right: 25%;
  }
}