.admin-trainer-container{
  .admin-trainer-body{
    margin: 10px 20px;
  }
}

.admin-filters{
  display: flex;
  justify-content: flex-end;
  margin: 2px 4px;
  .admin-filter-item{
    select{
      border: none;
      font-family: YekanRegular;
      text-align: center;
      outline: none;
    }
  }
}


.red-bullet{
  background-color: #ea3f3f;
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.green-bullet{
  background-color: #1bbd1b;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.admin-trainer-card{
  background-color: #efefef;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  align-items: center;
  margin: 10px 0;
}
.admin-trainer-card-info{
  display: flex;
  align-items: center;
}

.admin-trainer-card-labels{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 5px;
}

.admin-trainer-card-names{
  font-family: YekanBold;
  font-size: 15px;
}
.admin-trainer-card-mobile{
  font-family: YekanMedium;
}
.admin-trainer-card-end-date{
  font-family: YekanRegular;
  color: gray;
  font-size: 13px;
}
.admin-trainer-card-image{
  width: 80px;
  height: 80px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
  }
}

.admin-trainer-card-status{
  display: flex;
  flex-direction: column;
  align-items: center;
  .admin-trainer-card-average{
    font-family: YekanBold;
    font-size: 18px;
  }
}

.float-add-btn-admin{
  right: 40px !important;
}