.dumbbell-add-trainee-form{

  margin: 15px 10px;
  .dumbbell-add-trainee-form-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .select-wr{
      background-color: #EBEBEB;
      padding: 8px 5px;
      border-radius: 5px;
      min-width: 160px;
    }
    select{
      background-color: #EBEBEB;
      width: 100%;
      font-family: YekanRegular;
      text-align: center;
      border: none;
    }
    input{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      text-align: center;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 4px;
      direction: rtl;
    }
    textarea{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      text-align: center;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 8px;
      direction: rtl;
    }
    span{
      text-align: right;
      font-family: YekanMedium;
    }
  }

  .dumbbell-add-trainee-form-submit{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    span{
      background-color: #605BFF;
      color: white;
      font-family: YekanRegular;
      width: 200px;
      border-radius: 5px;
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
    }
    span:active{
      opacity: 0.3;
    }

  }

}


.dumbbell-add-trainee-switch-item{
  display: flex;
  justify-content: center;
  align-items:center;
  span{
    font-family: YekanBold;
    margin: 0 15px;
  }
}
