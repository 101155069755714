.dumbbell-add-trainee-form{

  margin: 15px 10px;
  .land-input{
    input{
      width: 100%;
    }
  }
  .dumbbell-add-trainee-form-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .select-wr{
      background-color: #EBEBEB;
      padding: 8px 5px;
      border-radius: 5px;
      min-width: 160px;
    }
    select{
      background-color: #EBEBEB;
      width: 100%;
      font-family: YekanRegular;
      text-align: center;
      border: none;
    }
    input{
      background-color: #EBEBEB;
      font-family: YekanRegular;
      text-align: center;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 4px;
      direction: rtl;
    }
    textarea{
      text-align: center;
      background-color: #EBEBEB;
      font-family: YekanRegular;
      border: none;
      border-radius: 5px;
      min-width: 160px;
      padding: 8px 8px;
      direction: rtl;
    }
    span{
      text-align: right;
      font-family: YekanMedium;
    }
  }

  .dumbbell-add-trainee-form-submit{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    span{
      background-color: #605BFF;
      color: white;
      font-family: YekanRegular;
      width: 200px;
      border-radius: 5px;
      padding: 4px 10px;
      text-align: center;
      cursor: pointer;
    }
    span:active{
      opacity: 0.3;
    }

  }

}

.dumbbell-login-btn{
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    background-color:#B667E5;
    color: white;
    border-radius:8px;
    width:235px;
    text-align: center;
    padding:8px 10px;
    font-family: YekanRegular;
    cursor: pointer;
  }
  span:active{
    opacity:0.3
  }
}

.area-inline{
  textarea{
    width: 100% !important;
  }

}

.dumbbell-invoice-page-pay-type{
  background-color:#EBEBEB;
  margin:8px 16px;
  border-radius: 8px;
  padding: 12px;
  h5{
    text-align: right;
    font-family: YekanBold;
    font-size: 14px;
  }
  .dumbbell-invoice-page-pay-type-options{
    display: flex;
    align-items: center;
    justify-content: space-around;
    .dumbbell-invoice-page-pay-type-item{
      display: flex;
      align-items: center;
      font-family: YekanMedium;
      input{
        width: 20px;
        height: 20px;
        margin: 0 5px;
      }
    }
  }
}
.dumbbell-invoice-page-pay-prev{
  background-color:#EBEBEB;
  margin:8px 16px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:100px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.dumbbell-invoice-page-pay-image{
  background-color:#EBEBEB;
  margin:8px 16px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:100px;
  img{
    width: 100%;
  }
  span{
    font-family: YekanRegular;
    color: gray;
  }
}

.dumbbell-discount_code-row{
  display: flex;
  align-items: center;
  background-color:#EBEBEB;
  margin: 16px 8px;
  border-radius: 6px;
  padding: 8px;
  input{
    flex: 4;
    border: none;
    border-radius: 4px;
    padding: 4px;
    text-align: center;
    max-width: 140px;
  }
  .dumbbell-otp-row-label{
    flex: 8;
    h5{
      padding:0;
      margin:0;
    }
    .otp-btn{
      background-color: #f7b239;
      text-align: center;
      font-family:YekanMedium;
      font-size:14px;
      color: white;
      border-radius: 4px;
      padding: 4px 4px;
      margin: 0 0 0 8px;
    }
  }
}

.dumbbell-otp-row{
  display: flex;
  align-items: center;
  background-color:#EBEBEB;
  margin: 8px 0;
  border-radius: 6px;
  padding: 8px;
  input{
    flex: 4;
    border: none;
    border-radius: 4px;
    padding: 4px;
    text-align: center;
    max-width: 140px;
  }
  .dumbbell-otp-row-label{
    flex: 8;
    h5{
      padding:0;
      margin:0;
    }
    .otp-btn{
      background-color: #8e8af5;
      text-align: center;
      font-family:YekanMedium;
      font-size:14px;
      color: white;
      border-radius: 4px;
      padding: 4px 4px;
      margin: 0 0 0 8px;
    }
  }
}

.dumbbell-invoice-page-form-verify-mobile{
  background-color:#EBEBEB;
  margin:8px 10px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dumbbell-invoice-page-form-verify-btn{
    width:90%
  }
  h5{
    font-family: YekanBold;
    font-size: 13px;
    background-color: orange;
    color: white;
    width: 100%;
    text-align:center;
    padding: 4px;
    border-radius: 4px;
    margin: 8px 0;

  }
  input{
    border-radius: 4px;
    border: none;
    font-family: YekanRegular;
    width: 90%;
    font-size: 14px;
    text-align: center;
    padding: 8px;
    outline: none;
  }
  p{
    font-family: YekanRegular;
    direction: rtl;
    margin: 4px 16px;
    font-size: 13px;
  }
}
