.package-card{
  background-color: #F8F8FF;
  border:1px solid #BCB7B7;
  margin: 10px 20px;
  border-radius: 8px;
  padding: 15px 20px;

  .package-card-header{
    position: relative;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #D0D0D0;
    h6{
      font-family: YekanHeavy;
      font-size: 14px;
    }
    img{
      position: absolute;
      left: 0px;
      top: -5px;
      background-color: #ffcc67;
      padding: 5px;
      border-radius: 4px;
      margin: 0 5px;
    }
  }

  .package-card-des{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 5px;
    .package-card-des-item{
      display: flex;
      align-items: center;
      justify-content:flex-end ;
      margin: 2px 0;
      .package-card-des-item-label{
        font-family: YekanRegular;
        direction: rtl;
      }
      .package-card-des-item-bullet{
        width:15px;
        height:15px;
        border-radius: 20px;
        background-color: #C2B9F2;
        margin: 0 5px;
      }
    }

  }

  .package-card-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    span{
      direction: rtl;
    }
    .package-card-pay_amount{
      font-family: YekanHeavy;
      color: #696868;
      font-size: 20px;
    }
    .package-card-amount{
      font-family: YekanRegular;
      color: #A8A8A8;
      font-size: 13px;
      text-decoration: line-through;
    }
    .package-card-extra_label{
      font-family: YekanRegular;
      font-size: 13px;
      color:#8D89FD
    }
  }
  .package-card-btn{
    display: flex;
    justify-content: center;
    span{
      width: 50vw;
      padding: 8px 10px;
      border-radius: 4px;
      font-family: YekanRegular;
      color: white;
      background-color: #8D89FD;
      text-align: center;
    }
  }

}