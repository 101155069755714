.dumbbell-video-upload-wr{
  margin-bottom: 20px;
  position: relative;
  padding: 12px;
  border-radius: 12px;
  background-color: #f5f5f5;
  margin: 12px;
  text-align: center;
  .dumbbell-video-upload-sec{
    background-color: #FAF8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 20px 10px 20px;
    border-radius: 10px;
    height: 120px;
  }

  .hint-message{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    span{
      font-family: YekanMedium;
      color: #FF7B31;
    }

  }

}

.dumbbell-video-upload-title{
  display: flex;
  justify-content: center;
  background-color:red;
  margin: 20px;
  input{
    min-width:100%;
    border: none;
    background-color:#FAF8F8;
    direction: rtl;
    text-align:center;
    padding: 10px;
    font-family: YekanMedium;
  }

}

.dumbbell-video-upload-btn{
  cursor: pointer;
  margin: 20px;
  background-color:#8D89FD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  div{
    width: 100%;
    font-family: YekanRegular;
    color: white;
    text-align: center;
  }
}
.dumbbell-video-upload-btn:active{
  opacity:0.3;
}


.edit-video-icon{
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #d5d5d5;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.image-upload-section{
  padding: 12px;
  width: 100%;

}
.pre-upload-image{
  width: 100%;
  border-radius: 12px;
}