.ant-drawer-content {
  border-radius: 15px 15px 0 0 !important;
}

.dumbbell-login-container {


  .v2-dumbbell-login-image {
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .v2-dumbbell-login-image-wrapper {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    top: 0;
    z-index: 1;
  }

  .v2-dumbbell-login-logo {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      font-size: 50px;
      margin-bottom: 10px;
      margin-top: 80px;
      letter-spacing: 25px;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }

    h6 {
      font-size: 20px;
      font-weight: lighter;
      margin-bottom: 30px;
    }

    span {
      font-size: 10px;
      font-family: "Roboto", sans-serif;
    }

  }

  .dumbbell-login-copyright {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 12px
  }

  .v2-dumbbell-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px
  }

  .v2-dumbbell-login-select {
    display: flex;
    background-color: #F3F3F3;
    align-items: center;
    width: 280px;
    padding: 16px;
    border-radius: 8px;
    margin: 10px 0;

    span {
      flex: 10;
      font-family: YekanRegular;
      margin: 0 10px;
      color: #6D6D6D;
      text-align: center;
    }

    img {
      width: 12px;
      margin: 0 0 0 10px;
    }
  }

  .v2-dumbbell-login-input {
    display: flex;
    background-color: #F3F3F3;
    align-items: center;
    width: 280px;
    padding: 16px;
    border-radius: 8px;
    margin: 10px 0;

    input {
      flex: 10;
      font-family: YekanRegular;
      margin: 0 10px;
      color: #6D6D6D;
      width: 100%;
      border: none;
      font-size: 14px;
      background-color: transparent;
      text-align: center;
      outline: none;
    }

    img {

      margin: 0 0 0 10px;
    }
  }

  .v2-dumbbell-login-btn {
    display: flex;
    background-color: #8D89FD;
    align-items: center;
    width: 280px;
    padding: 16px;
    border-radius: 8px;
    margin: 10px 0;
    justify-content: center;

    img {
      width: 20px;
    }

    span {
      color: white;
      font-family: YekanBold;
      text-align: center;
    }
  }

}


.login-hint-icon {
  position: absolute;
  top: 20px;
  left: 25px;
}


.key-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 15px 0;

  img {
    width: 18px;
    margin: 0 20px 0 0;
  }

}

.container {
  width: 200px;
}

.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;

  /* light theme */
  color: #272729;
  background-color: #F3F3F3;

  /* dark theme */
  color: #2d2d2d;
  background-color: #F3F3F3;
  box-shadow: none;
}

.v2-dumbbell-login-form-title {
  font-family: YekanRegular;
  margin: 10px 0;
  color: #b1b1b1
}

.v2-dumbbell-login-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:250px;
  .v2-dumbbell-login-links-item {
    display: flex;
    align-items: center;

    .v2-dumbbell-login-links-label {
      font-family: YekanRegular;
      font-size:11px;
      color: #a0a0a0;
    }
    .v2-dumbbell-login-links-item-resend{
      margin: 0 10px;
      span{
        background-color:#ebebeb;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: YekanBold;
      }
    }
  }
}
