.dumbbell-login-container{

  .dumbbell-login-image{
    display: flex;
    justify-content: center;
    margin: 60px 0 0 0;
  }

  .dumbbell-login-logo{
    display: flex;
    justify-content: center;
    margin: 5px 0 0 0;
  }

  .dumbbell-login-input-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    .dumbbell-login-input{
      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 7px 12px;
      border-radius:8px;
      input{
        width: 200px;
        text-align: center;
        font-family: YekanRegular;
        outline:none;
        border: none;
        font-size: 15px;
      }
    }
  }

  .dumbbell-login-btn{
    margin: 20px 0 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      position: relative;
      background-color:#B667E5;
      color: white;
      border-radius:8px;
      width:255px !important;
      text-align: center;
      padding:8px 10px;
      font-family: YekanRegular;
      cursor: pointer;
    }
    span:active{
      opacity:0.3
    }
  }

  .dumbbell-login-copyright{
    position:absolute;
    bottom:10px;
    text-align: center;
    width:100%;
    font-size:12px
  }

}


.dumbbell-login-return-link{
  display:flex;
  justify-content:center;
  position:absolute;
  bottom:-24px;
  font-family: YekanRegular;
  font-size:12px;
  color: #3e3e3e;
  cursor:pointer;
}
.dumbbell-login-return-link:active{
  opacity:0.3
}
