.plan-list-header-v2{
  background-color: #EEEEEE;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 10px 5px;
  margin: 10px 20px;
  border-radius: 8px;
  .plan-list-header-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0  10px;
    .name{
      font-family: YekanBold;
    }
    .mobile{
      font-family: YekanThin;
      font-size: 13px;
    }
  }
  .plan-list-header-image{
    position: absolute;
    left: 20px;
    top: -10px;
    width: 80px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 100px;
      object-fit: cover;
      border: 2px solid white;
    }
  }
}

.plan-list{

  margin: 5px 20px 10px 20px;
  padding-bottom: 70px;

  .plan-card-list{
    margin: 20px 0;
    .plan-card-header{
      border-radius: 8px 8px 0 0;
      background-color: #56c1e7;
      display: flex;
      justify-content: flex-end;
      padding: 6px 10px;
      align-items: center;

      img{
        width: 25px;
        padding: 5px;
        cursor: pointer;
      }
      img:active{
        opacity: 0.3;
      }
      span{
        font-family: YekanBold;
        color: white;
        font-size: 13px;
      }
    }

    .plan-card-body-v2{
      background-color: #E2E1FF;
      border-radius: 8px;
      border:1px solid #B6B6B6;
      padding:10px 0;

      .plan-card-footer{
        display: flex;
        justify-content: center;

        padding: 10px 20px;
        .plan-card-footer-btn{
          span{
            font-family: YekanBold;
            color: white;
            background-color: #8D89FD;
            border-radius: 5px;
            padding: 4px 20px;
            min-width: 200px;
            text-align: center;
            display: block;
          }
          span:active{
            opacity: 0.3;
          }
        }

        .plan-card-footer-price{
          direction: rtl;
          span{
            font-family: YekanBold;
          }

        }
      }

    }

  }

}


.dumbbell-header-plan-card-actions{
  display: flex;
  align-items: center;
  div{
    margin: 0 10px;
  }
  .dumbbell-trainee-card-box:active{
    opacity: 0.3;
  }
  .dumbbell-trainee-card-box{
    cursor: pointer;
  }
}

.plan-card-target{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 30px;
  padding: 10px 10px 0px 10px;
  border-top: 1px solid lightgrey;
  span{
    font-family: YekanBold;
    direction: rtl;
  }
  p{
    font-family: YekanRegular;
    color: gray;
  }
}

.actions-v2{
  display: flex;
  justify-content: space-between;
  margin: 4px 20px;
  align-items: baseline;
}


.plan-ref-number{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid lightgrey;
  padding: 4px 0px;
  margin: 0 30px;
  span{
    font-family: YekanBold;
    direction: rtl;
    font-size: 13px;
    color: gray;
  }
  p{
    font-family: YekanRegular;
    color: gray;
    margin: 0;
    font-size: 13px;
  }
}